
.stopwatch-info,
.circle-info {
	font-size: 134px;
}

ul {
	padding-left: 2em;
}

.x-popup ul {
	padding-left: inherit;
}

&.anonymous {
	#page {
		.language-switcher-row {
			top: 16px;
			margin: 0;
			&.has-verif-msg {
				top: 18px;
				right: 10px;
			}
		}
		
		&.action-email_subscribe {
			width: calc(~'100% + 40px');
			margin: -20px;
			
			&.straight {
				.banner {
					background-image: url("../../../../v-00000000001/v3/img/skins/default/red/premium-landings/camp2/bg-straight-mobile.png");
				}
			}
			&.gay {
				.banner {
					background-image: url("../../../../v-00000000001/v3/img/skins/default/red/premium-landings/camp2/bg-gay-mobile.png");
				}
			}
			&.trans,
			&.shemale {
				.banner {
					background-image: url("../../../../v-00000000001/v3/img/skins/default/red/premium-landings/camp2/bg-trans-mobile.png");
				}
			}
			
			.banner {
				background-size: contain;
				background-position: center 50px;
				
				&:before {
					padding-top: calc(~'122.22% + 52px');
					background: rgb(0,0,0);
					background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%);
				}
				
				.arrow {
					bottom: 17px;
				}
			}
			
			.banner-content {
				position: relative;
				top: auto;
				right: auto;
				width: 100%;
				margin-top: -150px;
				transform: none;
				
				.marketing-mentions {
					position: relative;
					padding: 50px 5% 75px 5%;
					background: #111111;
					
					&:before,
					&:after {
						content: '';
						position: absolute;
						left: 5%;
						display: block;
						width: 90%;
						height: 2px;
						background: @red-color;
					}
					&:before {
						top : 25px;
					}
					&:after {
						bottom : 50px;
					}
					
					p {
						width: 100%;
						text-transform: uppercase;
					}
				}
			}
			
			.content-page {
				position: relative;
				padding: 60px 0;
				background: url("../../../../v-00000000001/v3/img/skins/default/red/email-subscribe/bg-form-mobile.png") no-repeat center top / cover;
				
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 100%;
					background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%);
					z-index: 0;
				}
			}
			
			#optin-form {
				position: relative;
				z-index: 1;
				
				.form-group {
					&.form-field-email-subscribe-form_name,
					&.form-field-email-subscribe-form_email,
					&.form-field-email-subscribe-form_typexxx {
						label {
							font-size: 14px;
							font-weight: normal;
						}
					}
					
					&.form-field-email-subscribe-form_name {
						padding-top: 20px;
					}
					
					&.form-field-email-subscribe-form_typexxx {
						margin-bottom: 20px;
					}
					
					&.form-field-email-subscribe-form_marketing_internal,
					&.form-field-email-subscribe-form_marketing_partners {
						padding-bottom: 10px;
						
						.content {
							padding: 0 30px 0 40px;
							
							label {
								font-size: 14px;
							}
						}
					}
					
					&.form-buttons {
						.btn-danger {
							width: 100%;
							padding: 20px 0;
						}
					}
				}
				
			}
			
			.contact-footer {
				
				h2 {
					font-size: 24px;
				}
				
				ul {
					li {
						& ~ li {
							margin-left: 30px;
						}
					}
				}
				
			}
			
		}
	}
}


.info-bordered {
	font-size: 13px;
	line-height: 18px;
	
	ul {
		margin: 5px 0;
		padding: 0;
	}
	
	.btn {
		white-space: normal;
	}
}

.panel {
	margin-bottom: 8px;
	margin-top: 8px;
}

&.action-video_edit {
	#main {
		.video-recap {
			padding: 0 1px;
			
			.video-details {
				padding: @account-tabs-padding*2 @account-tabs-padding*4;
			}
		}
	}
}

#main {
	margin-left: -@account-panel-padding;
	margin-right: -@account-panel-padding;
	padding: 0;
	
	#dashboard {
		.tabs {
			padding: 0 @account-panel-padding*1.5;
		}
		
		.panel {
			margin-top: 0;
			margin-bottom: 16px;
		}
	}
	
	#content {
		//min-height: calc(~"100vh - 280px");
		.min-height-100vh(280px);
		
		#account-tab-nav {
			margin-top: 6px;
			
			li {
				&.with_hover_sub_menu:hover {
					& > a.active {
						background: @body-bg;
					}
					
					.nav-tab-sub-menu {
						&,
						&.active {
							display: none;
						}
					}
				}
			}
		}
		
		#revshare_form_contener {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	
	#account-content-block {
		border-left: 0;
		border-right: 0;
		border-radius: 0;
		
		.form-horizontal {
			& > fieldset {
				padding-left: @account-panel-padding*2;
				padding-right: @account-panel-padding*2;
			}
			
			button.submit {
				font-size: 16px;
				padding: 10px 20px;
			}
			
			#upload_form_sponsorlinks {
				.sponsor-links-actions {
					margin-top: 0;
				}
			}
		}
		
		.locale-selector-popup {
			margin-right: @account-panel-padding*2;
			
			.x-popup-content {
				ul {
					a {
						font-size: 16px;
						height: 34px;
						line-height: 34px;
						padding: 0 @account-panel-padding;
					}
				}
			}
		}
		
		.instructions {
			&.warning {
				padding: @account-panel-padding @account-panel-padding*2;
			}
			
			ul {
				padding-left: @account-panel-padding*3;
			}
		}
	}
	
	#footer {
		padding: 0 @account-panel-padding*1.5;
	}
	
	.file-upload-inputs {
		.file-upload-or {
			padding-top: 0;
		}
	}
	
	#friendsrequests-actions {
		#friendsrequests-check-all {
			width: 100%;
		}
		
		& > div:first-child {
			float: none;
		}
		
		#friendsrequests-accept,
		#friendsrequests-refuse {
			margin-left: 0;
			text-align: center;
			width: 50%;
			
			.label {
				display: block;
			}
		}
	}
	
	.mozaique.profileslist {
		.thumb-block {
			.friend-request-actions {
				overflow: hidden;
				
				label {
					height: 44px;
					line-height: 44px;
				}
				
				button {
					height: 44px;
					line-height: 48px;
				}
			}
			
			&.profilepic {
				.pic-request-actions {
					.btn {
						padding: 6px 0;
					}
				}
			}
		}
	}
}


#chunk-progress-container {
	right: 0;
	
	.progress {
		height: 18px;
		
		.progress-bar {
			line-height: 18px;
		}
	}
}

.nav-tabs {
	& > li {
		width: 20%;
		
		& > a {
			padding: 10px 4px 5px;
			text-align: center;
			
			.icon-f {
				font-size: 24px;
			}
			
			.txt {
				display: block;
				font-size: 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}

.nav-tabs-5 > li {
	width: 20%;
}

.nav-tabs-4 > li {
	width: 25%;
}

.nav-tabs-3 > li {
	width: 33.33%;
}

.nav-tabs-2 > li {
	width: 50%;
}

.video-list {
	.video {
		.video-thumb {
			padding-bottom: @account-panel-padding;
			padding-right: 2*@account-panel-padding;
		}
		
		.video-details {
			padding-left: 2*@account-panel-padding;
		}
	}
}

#nav {
	.account-main-categories {
		@media @media-ratio-vertical {
			overflow: auto;
			max-height: none;
			//max-height: calc(~"100vh - 82px");
			.max-height-100vh(82px);
		}
	}
}

#recently-deleted-videos,
#recently-replacement-videos,
#pending-uploads {
	p.block-title {
		font-size: 16px;
	}
}

#main-big-stats {
	.frame {
		font-size: 12px;
		line-height: 16px;
		padding: 4px 0;
		
		strong {
			font-size: 14px;
		}
	}
}

table#video-tab-list tr td.title {
	max-width: 240px;
	min-width: 240px;
}

#video-tab-customize {
	.filter {
		label {
			padding: 8px 8px 7px 30px;
			
			.custom-checkbox {
				.icon-f {
					font-size: 16px;
					line-height: 32px;
				}
			}
		}
	}
}

h2.fatal-error {
	font-size: 24px;
	margin: 40px 0 25px;
}

&.section-uploads #profile-vids-release-rates {
	.info {
		float: right;
	}
}

#main-stats-chart {
	#stats-views-chart {
		height: 600px;
	}
}

&.section-photos {
	&#page {
		.mozaique {
			.thumb-block {
				&.profileban {
					width: 50%;
				}
			}
		}
		
		#main #account-content-block {
			#main-picture-upload,
			#main-banner-upload {
				#main-picture-upload_image-display-error,
				#main-banner-upload_image-display-error {
					.icon-f.icf-file-broken {
						font-size: 36px;
					}
					
					.main-picture-upload_image-error-content,
					.main-banner-upload_image-error-content {
						margin: -25px 0 0 15px;
					}
				}
			}
		}
	}
	
	&.action-galleries {
		#create-form.is-init {
			margin-top: 0;
			
			& + h2 {
				margin-top: 1em;
			}
		}
		
		h2 {
			display: block;
			margin-top: 0;
			margin-bottom: 0.5em;
			
			& + .btn-new-album {
				display: block;
				margin-left: 0;
				margin-bottom: 1em;
			}
		}
	}
	
	&.action-gallery_edit {
		#account-content-block {
			.gallery-panel {
				.col-pic {
					width: 80px;
				}
				
				.col-infos,
				#edit-form {
					width: ~"calc(100% - 90px)";
				}
				
				.gallery-panel-title {
					margin-bottom: 15px;
				}
			}
		}
	}
}

.language-switcher-row.has-verif-msg {
	top: 0;
	right: 10px;
}

#update-email-prefs_prefs_marketing {
	h3 {
		position: absolute;
		top: 50%;
		left: 0;
		width: 50%;
		transform: translateY(-50%);
		div {
			.marketing-content {
				font-size: 26px;
				span {
					font-size: 130px;
				}
			}
			.logo {
				svg {
					margin-bottom: 0;
					&,
					& > image {
						width: 180px;
						height: 45px;
					}
				}
				.icf-ticket-red {
					font-size: 58px;
				}
			}
		}
	}
	.marketing-mentions,
	.form-group {
		width: 50%;
	}
}
