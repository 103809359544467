&.section-photos#page {
	
	#main {
		#account-content-block {
			.btn-upload.init-ok {
				opacity: 1;
				cursor: pointer;
				
				&[disabled] {
					cursor: default;
					opacity: 0.7;
				}
			}
		}
	}
	
	&.action-galleries,
	&.action-gallery_edit {
		.mozaique {
			.thumb-block {
				.thumb-inside {
					img {
						border: 0;
					}
				}
			}
		}
	}
	
	.album-menu {
		position: relative;
		padding: 30px;
		background: @account-panel-header-bg;
		border: 1px solid darken(@account-panel-border, 5%);
		
		&.is-close {
			height: 0;
			padding: 0;
			border: none;
			overflow: hidden;
		}
		
		&.is-pined {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
			z-index: 210;
		}
		
		.album-menu-title {
			display: inline-block;
			margin: 0 15px 0 0;
			font-size: 20px;
			line-height: 1;
			vertical-align: middle;
		}
		
		.btn {
			margin: 0;
			line-height: 1;
			&:focus {
				outline: unset;
			}
			
			&.btn-move-first {
				margin-right: 10px;
				border-color : #cccccc;
				
				&:hover {
					background-color: #cccccc;
					border-color: #adadad;
				}
				.icon-f {
					margin-right: 5px;
				}
			}
			
			&.btn-massive-cancel {
				position: absolute;
				top: 50%;
				right: 30px;
				padding: 0;
				font-size: 16px;
				background: none;
				transform: translateY(-50%);
			}
		}
		
	}
}

&.action-galleries,
&.action-gallery_edit {
	
	.alert-warning {
		padding: 10px;
	}
	
	.panel-body {
		.btn {
			cursor: default;
			line-height: 18px;
			padding: 6px 12px;
			opacity: 0.5;
			
			&:focus {
				outline: unset;
			}
			
			&.init-ok {
				opacity: 1;
				cursor: pointer;
			}
		}
		
		.nbr-per-page {
			height: 32px;
			
			&, &:focus {
				font-size: 12px;
			}
		}
	}
	
	.form-group.form-buttons {
		display: none;
	}
	
	.form-button {
		overflow: hidden;
		margin: 15px -15px;
		text-align: center;
	}
	
	.create-form-uploads {
		width: 100%;
		overflow: hidden;
		opacity: 0.5;
		
		&.init-ok {
			opacity: 1;
			
			.upload-require {
				opacity: 1;
			}
			
			input[type="file"] {
				cursor: pointer;
			}
		}
		
		.legend {
			padding: 0;
			margin: 0 2px 18px 2px;
			font-size: 18px;
			line-height: inherit;
			color: @gray-dark;
			border-bottom: 1px solid @account-panel-border;
		}
		
		.upload-require {
			position: absolute;
			bottom: 0;
			left: 2px;
			display: block;
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: left;
			font-size: 10px;
			line-height: 1.3;
			color: #ffffff;
			opacity: 0;
		}
		
		.upload-require-item {
			display: block;
		}
	}
	
	.form-group.form-field-picture_file {
		position: relative;
		margin: 0;
		overflow: hidden;
		
		&:before {
			content: '';
			display: block;
			padding-top: 75%;
		}
		
		.content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
		}
		
		.form-control-static {
			position: absolute;
			display: flex;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			align-items: center;
			justify-content: center;
			background: @btn-danger-bg;
			border: 1px solid @btn-danger-border;
			border-radius: @btn-border-radius-base;
			z-index: 0;
			
			&.is-init {
				background: #fff;
				border-color: @btn-default-border;
				border-radius: 0;
				
				.btn {
					.icon-f {
						font-size: 15px;
						color: black;
					}
				}
			}
			
			.notouch &:hover {
				background: @btn-danger-border;
				
				.icon-f {
					&.icf-plus-square {
						background: @btn-danger-border;
					}
				}
			}
			
			input[type="file"] {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				opacity: 0;
				z-index: 2;
			}
			
			.icon-f {
				position: relative;
				font-size: 20px;
				color: white;
				z-index: 0;
				cursor: pointer;
				
				&.icf-plus-square {
					position: absolute;
					top: 50%;
					left: 50%;
					display: block;
					font-size: 20px;
					line-height: 1;
					border-radius: 3px;
					background: #de2600;
				}
				
				&.icf-spinner {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 20px;
					height: 20px;
					margin: -10px 0 0 -10px;
					font-size: 20px;
					color: #999;
					z-index: 0;
				}
			}
			
			i.icon-f {
				display: none;
			}
			
		}
	}
	
	.mozaique {
		p {
			height: auto;
		}
		
		.thumb-block {
			
			&.is-cover {
				.embed-responsive-item {
					border-color: @red-color;
				}
				
				.btn-cover-gallery,
				.btn-cover-gallery.is-init {
					display: none;
				}
			}
			
			&.btn-block {
				.embed-responsive-item {
					border: 0;
				}
				
				.btn {
					position: relative;
					top: auto;
					right: auto;
					width: 100%;
					height: 100%;
				}
			}
			
		}
		
		.thumb-inside {
			padding-bottom: 75%;
		}
		
		.embed-responsive-item {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #ccc;
			overflow: hidden;
		}
		
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			z-index: 1;
			transform: translate3D(-50%, -50%, 0);
		}
		
		.btn {
			background: @btn-default-bg;
			border: 1px solid @btn-default-border;
			display: inline-block;
			white-space: normal;
			
			&:hover {
				background: darken(@btn-default-bg, 10%);
				border: 1px solid darken(@btn-default-border, 10%);
			}
			
			&.btn-danger {
				background-color: @btn-danger-bg;
				border-color: @btn-danger-border;
				
				&:not([disabled]):hover {
					background-color: @btn-danger-border;
				}
			}
			
			&.btn-lg {
				position: absolute;
				right: 2px;
				top: 2px;
				width: 30px;
				height: 30px;
				margin: 0;
				padding: 0;
				line-height: 30px;
				font-size: 13px;
				z-index: 3;
				
				.notouch &:hover {
					background: darken(@btn-default-bg, 10%);
					border: 1px solid darken(@btn-default-border, 10%);
				}
				
				&.btn-cover-gallery {
					right: 34px;
				}
			}
			
			&.btn-upload {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 14px;
			}
			
			&[disabled] {
				cursor: default;
				opacity: 0.8;
			}
			
		}
		
		.confirm {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			text-align: center;
			font-size: 12px;
			color: #fff;
			background: rgba(0, 0, 0, 0.5);
			z-index: 3;
			
			& > span {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				width: 100%;
				height: auto;
				transform: translateY(-50%);
			}
		}
		
		.icon-f.icf-spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 20px;
			height: 20px;
			margin: -10px 0 0 -10px;
			text-align: center;
			font-size: 20px;
			line-height: 20px;
			z-index: 0;
		}
		
		.image-name {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: auto;
			text-align: center;
			font-size: 12px;
			color: white;
			background: rgba(0, 0, 0, 0.5);
			cursor: pointer;
			z-index: 1;
		}
		
		.pic-gif {
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 2px 5px;
			color: white;
			background: @btn-danger-bg;
			z-index: 1;
		}
	}
}

&.action-galleries {
	h2 {
		display: inline-block;
		margin-bottom: 1em;
		
		& + .btn-new-album {
			display: inline-block;
			margin-left: 15px;
			opacity: 0.5;
			cursor: default;
			
			&.init-ok {
				opacity: 1;
				cursor: pointer;
			}
			
			.icon-f {
				display: inline-block;
				vertical-align: top;
				margin-top: 1px;
			}
		}
	}
	
	p.no-gallery {
		margin-top: 1em;
		text-align: center;
		font-weight: bold;
	}
	
	#create-form {
		
		.create-form-title {
			margin: 1em 0;
			font-size: 28px;
			font-weight: 700;
			line-height: 1.1;
		}
		
		#new_gallery,
		#picture {
			.form-group.form-field-picture_file {
				margin: 0;
			}
			
			.form-group.form-buttons {
				display: none;
			}
		}
	}
	
	.mozaique {
		a.embed-responsive-item:hover {
			border-color: @link-color;
		}
		
		img {
			max-height: 135%;
		}
		
		.gallery-infos {
			position: absolute;
			left: 0;
			bottom: 0;
			display: block;
			width: 100%;
			padding: 30px 5px 0 5px;
			margin: 0;
			text-align: left;
			color: white;
			background: rgb(0, 0, 0);
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
			z-index: 1;
			
			p {
				margin: 0;
				color: white;
			}
			
			.gallery-title {
				margin-bottom: 2px;
				font-size: 15px;
				font-weight: bold;
			}
			
			.gallery-desc {
				font-size: 13px;
				font-weight: bold;
			}
			
			.gallery-status {
				font-size: 11px;
			}
		}
		
		.thumb-inside.is-removing {
			opacity: 0.5;
		}
	}
}

&.action-gallery_edit {
	
	.gallery-panel {
		display: flex;
		flex-flow: row nowrap;
		position: relative;
		margin-bottom: 15px;
		padding: 10px;
		background: @gray-lighter;
		border: 1px solid darken(@account-panel-border, 5%);
		
		.col-pic {
			width: 180px;
			
			& + .col-infos {
				width: ~"calc(50% - 205px)";
			}
		}
		
		.col-infos {
			width: ~"calc(50% - 25px)";
			margin-left: 25px;
		}
		
		.col-pres {
			width: ~"calc(50% - 25px)";
			margin-left: 25px;
			text-align: right;
		}
		
		.btn-gallery-edit {
			padding: 0;
			margin: 0;
			margin-left: 5px;
			font-size: 16px;
			background: none;
			.icon-f {
				float: none;
				margin: 0;
			}
			small {
				font-size: 11px;
				font-weight: normal;
				color: @text-color;
			}
		}
		
		.gallery-cover {
			position: relative;
			width: 100%;
			max-height: 180px;
			background: @account-panel-bg;
			border: 1px solid #cccccc;
			overflow: hidden;
			
			img {
				display: block;
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
				margin: 0 auto;
				transition: opacity 1s;
			}
			
			.btn-gallery-edit {
				position: absolute;
				right: 2px;
				bottom: 2px;
				width: 30px;
				height: 30px;
				text-align: center;
				font-size: 12px;
				line-height: 30px;
				color: white;
				background: rgba(0, 0, 0, 0.7);
				border: 0;
				border-radius: @border-radius-small;
			}
		}
		
		.gallery-panel-title {
			margin-bottom: 25px;
			
			h2 {
				margin: 0;
			}
			
			a {
				text-decoration: none;
			}
			
			button {
				display: inline-block;
			}
		}
		
		.gallery-infos {
			margin-bottom: 20px;
			
			.gallery-stats {
				margin-bottom: 5px;
				
				.gallery-stats-vgood {
					display: inline-block;
					margin: 0;
					font-weight: bold;
					font-size: 18px;
					line-height: 1;
				}
				
				.gallery-stats-rating {
					display: inline-block;
					margin: 0 0 0 10px;
					font-weight: bold;
					font-size: 18px;
					line-height: 1;
				}
				
				.gallery-stats-vbad {
					display: inline-block;
					margin: 0 0 0 10px;
					line-height: 1;
				}
			}
			
			.gallery-voters {
				.gallery-voters-anonymous {
					display: block;
					font-style: italic;
					font-size: 12px;
				}
			}
		}
		
		.gallery-desc {
			position: relative;
			padding: 10px 30px 10px 10px;
			text-align: left;
			background: @account-panel-bg;
			border: 1px solid darken(@gray-lighter, 5%);
			
			p {
				margin: 0;
				font-style: italic;
				font-size: 14px;
			}
			
			.btn-gallery-edit {
				position: absolute;
				top: 2px;
				right: 2px;
			}
			
			& + .btns {
				margin-top: 10px;
			}
			&.hidden + .btns {
				margin-top: 0;
			}
		}
		
		#edit-form {
			width: 50%;
			margin-left: 25px;
			
			form .form-group.form-buttons {
				display: block;
			}
		}
	}
	
	#picture .form-buttons > div {
		float: none;
		width: 100%;
		margin-left: 0;
		text-align: center;
	}
	
	.is-selecting {
		.mozaique {
			.btn {
				display: none;
			}
		}
	}
	
	.reorder-touch {
		.mozaique {
			.thumb-block {
				&.is-selected {
					.btn {
						display: none;
					}
				}
			}
		}
	}
	
	.is-reordering {
		.mozaique {
			.thumb-block {
				&.is-cover {
					.embed-responsive-item {
						border-color: #ccc;
					}
				}
			}
		}
		&.solo {
			.mozaique {
				.thumb-block {
					cursor: grab;
					.btn {
						display: none;
					}
					&:after {
						transform: scale(1);
					}
				}
			}
		}
	}
	
	.mozaique {
		
		.thumb-block {
			
			&.is-dragdrop-active {
				.btn {
					display: none;
				}
			}
			
			&.is-dragdrop-selected {
				.btn {
					display: none;
				}
			}
			
			&.is-dragdrop-inactive {
				.btn {
					display: none;
				}
			}
			
			&.is-loading {
				.icon-f.icf-spinner {
					width: 40px;
					height: 40px;
					margin: -20px 0 0 -20px;
					font-size: 40px;
					line-height: 40px;
				}
			}
		}
	}
}

.is-uploading &.action-gallery_edit {
	.gallery-panel {
		display: none;
	}
}