.info-hover {
	display: inline-block;
	overflow: hidden;
	padding: 0;
	position: relative;
	vertical-align: bottom;
	
	.info {
		color: @account-video-details-color;
		position: relative;
		top: 1px;
	}
	
	&:hover {
		overflow: visible;
		
		.info-content {
			opacity: 1;
			transition: 0.4s all;
		}
		
		.info-back {
			opacity: 1;
			top: -32px;
			transition: 0.3s 0.2s all;
		}
	}
	
	.info-content {
		background: @account-panel-bg;
		box-shadow: 0 0 2px @text-color;
		left: -8px;
		min-width: 180px;
		opacity: 0;
		padding: 2px 8px;
		position: absolute;
		top: -2px;
		z-index: 2;
	}
	
	.info-back {
		background: @account-panel-bg;
		border-radius: 50%;
		color: @account-video-details-color;
		font-size: 40px;
		left: -10px;
		opacity: 0;
		position: absolute;
		top: -24px;
		z-index: 1;
	}
}

.sheer-tag {
	display: inline-block;
	background: #6f42c1;
	color: white;
	padding: 0 8px;
	font-weight: 700;
	height: 26px;
	line-height: 26px;
	.icf-sheer-circle {
		color: white;
		vertical-align: text-top;
	}
}