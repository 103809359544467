#upload-form-progress {
	margin: 40px 0;
	h5 {
		margin: 0 0 6px;
	}
	#iframe-upload {
		width: 100%;
		height: 0;
	}
}

&.action-video_upload,
&.action-video_edit,
&.action-verify_profile {
	#main,
	#main #content {
		overflow: visible;
		
		& > .row {
			margin-right: 0;
			margin-left: 0;
			
			#cur-tab-nav {
				padding-left: 0;
			}
			
			#cur-section-pan {
				padding-right: 0;
				@media (max-width: 1800px) {
					padding-left: 0;
				}
			}
		}
	}
	#main {
		#processing-status {
			font-size: 18px;
			margin: 20px 0;
			text-align: center;
			.alert-warning .icon-f {
				margin: 0 20px 0 0;
			}
			.alert-information {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: row;
				margin-top: 16px;
				background-color: @red-color;
				border-color: @red-color;
				color: white;
				font-size: 16px;
				font-weight: bold;
				.information-message {
					margin: 0px 16px;
				}
			}
		}
		#async-processing-status {
			font-size: 18px;
			margin: 20px 0;
			.icon-f {
				margin: 0 10px 0 0;
			}

			.upload-completed {
				margin-top: 20px;
				&.alert-success,
				&.alert-success a {
					color: #2c7c29;
				}
			}

		}
		.form-horizontal {
			& > fieldset {
				padding-left: 30px;
				padding-right: 30px;
				legend {
					margin: 0 -15px 18px;
					width: calc(~"100% + 30px");
					.icon-f {
						vertical-align: middle;
					}
				}
			}
			.instructions {
				padding: 2*@account-panel-padding 0;
				border-bottom: 0;
				.row {
					.row {
						text-align: center;
					}
				}
				&.main {
					border-bottom: 1px solid @account-panel-border;
					margin-bottom: 2*@account-panel-padding;
					position: relative;
				}
				&.warning {
					background: @warning-block-bg;
					border: 1px solid @warning-block-border;
					color: @warning-block-color;
					padding-left: 2*@account-panel-padding;
					padding-right: 2*@account-panel-padding;
					margin: 2*@account-panel-padding 0 @account-panel-padding;
					.title {
						font-size: 18px;
						.icon-f {
							margin-right: 6px;
						}
					}
					a {
						color: black;
						font-weight: bold;
					}
					ul + p {
						border-top: 1px solid #f5c6cb;
						margin-top: @account-panel-padding*2;
						padding-top: @account-panel-padding*2;
					}
				}
				&.wrench {
					border: 1px solid @account-panel-border;
					padding-left: 2*@account-panel-padding;
					padding-right: 2*@account-panel-padding;
					margin: 2*@account-panel-padding 0;
					p {
						margin-bottom: 0;
						.icon-f {
							font-size: 20px;
							margin-right: 6px;
						}
						a {
							font-weight: bold;
						}
					}
				}
				p {
					font-size: 14px;
					margin: 0 0 8px;
				}
				.badge.alert {
					background: @theme-color;
					border: 0;
					border-radius: 3px;
					color: #fff;
					padding: 3px 5px;
					text-transform: uppercase;
				}
			}
			.file-upload-inputs {
				font-size: 16px;
				margin-bottom: 64px;
				.file-upload-file,
				.file-upload-or,
				.file-upload-text {
					margin-bottom: 0;
					label.control-label {
						display: block;
						font-size: 16px;
						margin: 0 0 4px;
						text-align: center;
					}
					.form-group {
						margin: 0;
						.col-sm-12 {
							padding-left: 0;
							padding-right: 0;
						}
					}
					p {
						margin: 0;
					}
				}
				.file-upload-file {
					input,
					.content,
					.form-control-static {
						opacity: 0;
						height: 1px;
						margin: 0;
						min-height: 1px;
						width: 1px;
					}
					label.control-label {
						cursor: pointer;
						width: 100%;
						span {
							&:first-child {
								font-size: 16px;
								display: block;
								line-height: 24px;
								margin-bottom: 5px;
							}
							&.icf-upload {
								background: @red-color;
								border: 1px solid @account-panel-border;
								border-radius: 3px;
								color: white;
								display: block;
								font-size: 40px;
								height: 60px;
								line-height: 60px;
								margin: 0;
								padding: 0;
								text-align: center;
								transition: all 0.2s;
							}
						}
						&:hover {
							span {
								&.icf-upload {
									background: darken(@red-color, 10%);
								}
							}
						}
						&:active {
							span {
								&.icf-upload {
									box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) inset;
									line-height: 64px;
								}
							}
						}
					}
				}
				.file-upload-or {
					line-height: 60px;
					padding-top: 29px;
					text-align: center;
				}
				.file-upload-text {
					margin-bottom: 5px;
					label.control-label {
						line-height: 24px;
						margin-bottom: 5px;
					}
					input,
					select {
						border-right: 0;
						font-size: 18px;
						min-height: 60px;
						margin: 0;
						padding: 10px 12px;
					}
					.input-group-btn{
						vertical-align: top;
					}
					button {
						border-bottom-left-radius: 0;
						border-top-left-radius: 0;
						border-color: @input-border;
						min-height: 60px;
						&:active,
						&:focus {
							outline: none;
						}
					}
				}
				.file-upload-submit {
					display: none;
					.input-group {
						margin-bottom: 5px;
						.input-group-addon {
							height: 58px;
							padding: 0;
						}
						.type {
							border-right: 0;
							color: @red-color;
							padding: 6px 12px;
							* {
								display: none;
							}
							.is_file {
								font-size: 16px;
							}
							.is_url {
								font-size: 16px;
							}
						}
						.file-upload-recap-contener {
							border-left: 0;
							border-right: 0;
							cursor: default;
							height: 60px;
							overflow: hidden;
							padding: 0 10px;
							position: relative;
							.file-upload-recap,
							.return-file-select {
								font-size: 16px;
								height: 60px;
								transition: all 0.25s ease-in-out;
								width: 100%;
							}
							.file-upload-recap {
								display: table-cell;
								line-height: 20px;
								left: 0;
								overflow: auto;
								padding: 0;
								position: relative;
								vertical-align: middle;
								word-break: break-all;
							}
							.return-file-select {
								font-weight: bold;
								left: 10px;
								line-height: 60px;
								opacity: 0;
								overflow: hidden;
								position: absolute;
								text-align: center;
								top: 0;
								bottom: 0;
								white-space: nowrap;
							}
							&:hover {
								cursor: pointer;
								.file-upload-recap,
								.return-file-select {
									transition: all 0.25s 0.3s ease-in-out;
								}
								.file-upload-recap {
									left: -40px;
									opacity: 0;
								}
								.return-file-select {
									opacity: 1;
									left: 0;
								}
							}
						}
						.btn {
							background: @theme-color;
							border-left: 0;
							color: white;
							font-size: 16px;
							height: 58px;
							margin: 0;
							padding: 0 20px;
						}
					}
				}
				&.is_file .file-upload-submit .input-group .type .is_file,
				&.is_url .file-upload-submit .input-group .type .is_url {
					display: block;
				}
				&.is_url .file-upload-submit .help-block.is_file {
					display: none;
				}
			}
		}
		
		fieldset {
			&.captcha-button {
				padding: 20px 0 0;
				text-align: center;
				.form-group {
					.inline-block;
					button {
						font-size: 18px;
						padding: 24px 40px;
						white-space: normal;
						&:active,
						&:focus {
							outline: none;
						}
					}
				}
			}
			&.upload-video {
				margin-bottom: 0;
				input,
				button {
					height: 40px;
					line-height: 20px;
					padding: @account-panel-padding @account-panel-padding*2;
				}
				.form-group {
					margin-bottom: @account-panel-padding/2;
				}
				&.upload-video-file {
					.inline-block;
					.form-group {
						float: left;
						margin: 0;
						padding: 0;
						.form-control-static,
						.col-sm-12 {
							padding: 0;
						}
					}
				}
				&.upload-video-url {
					margin: 0 auto;
					max-width: 500px;
					.form-group {
						text-align: left;
					}
				}
			}
			.form-field-upload_form_video_premium_options_video_premium_options_centered_membership_price.form-group,
			.form-field-edit_form_video_premium_options_video_premium_options_centered_membership_price.form-group,
			.form-field-upload_form_video_premium_options_video_premium_options_centered_ppv_price.form-group,
			.form-field-edit_form_video_premium_options_video_premium_options_centered_ppv_price.form-group {
				display: none;
				&.init-ok {
					display: block;
				}
				label.control-label.col-sm-0,
				.content.col-sm-12 {
					width: auto;
					max-width: 400px;
					text-align: left;
					padding-left: 15px;
					padding-right: 15px;
					margin-left: auto;
					margin-right: auto;
				}
				.content.col-sm-12 {
					float: none;
				}
				select {
					font-size: 16px;
				}
			}
			.membership-will-activate {
				color: @red-color;
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 20px;
				a {
					color: @red-color;
				}
			}
			.form-field-upload_form_regional_event_regional_event_centered_regional_event {
				.btn.radio.recommended label {
					font-weight: bold;
					animation: regional_event_recommended 1.5s linear infinite;
				}
			}
			@keyframes regional_event_recommended {
				0% {
					background: @btn-default-bg;
				}
				70% {
					background: @red-color;
				}
				100% {
					background: @btn-default-bg;
				}
			}
			#premium-instruction-block,
			#regional-event-instruction-block {
				font-size: 14px;
				margin: 0 40px;
				position: relative;
				z-index: 2;
				border: 1px solid darken(@account-panel-bg, 5%);
				background: @text-invert-color;
				padding: 10px 20px 10px 16px;
				
				ul {
					list-style: initial;
					padding-left: 30px;
				}
			}
			#premium-instruction-block {
				.premium,
				.ppv,
				.membership {
					display: none;
				}
				&.premium-checked .premium,
				&.ppv-checked .ppv,
				&.membership-checked .membership {
					display: list-item;
				}
			}
			#regional-event-instruction-block {
				.selected,
				.not-selected {
					display: none;
				}
				&.selected .selected,
				&.not-selected .not-selected {
					display: list-item;
				}
			}
			&.desktop-choice-back-contener {
				overflow: hidden;
				legend {
					position: relative;
					z-index: 2;
				}
				.centered {
					position: relative;
					z-index: 1;
				}
				.form-group {
					position: relative;
					z-index: 2;
				}
				.desktop-choice-back {
					color: darken(@account-panel-bg, 3%);
					font-size: 180px;
					left: 50%;
					margin-left: -300px;
					position: absolute;
					top: -48px;
					z-index: 1;
					&.icf-sexe-man,
					&.icf-sexe-woman {
						font-size: 140px;
					}
					&.icf-sexe-man {
						margin-left: -320px;
						top: 12px;
					}
					&.icf-sexe-woman {
						margin-left: -210px;
					}
				}
				.form-field-upload_form_video_premium_options_video_premium_options_centered_premium_options,
				.form-field-edit_form_video_premium_options_video_premium_options_centered_premium_options {
					z-index: 3;
				}
				.video-premium-legend,
				.network-sites-legend,
				.privacy-legend,
				.blocked-countries-legend {
					position: relative;
					z-index: 2;
				}
			}
			.video-premium-legend,
			.blocked-countries-legend {
				font-size: 14px;
			}
			.network-sites-legend,
			.privacy-legend {
				font-size: 14px;
				display: none;
			}
		}
		
		#upload_form,
		#upload_form_premium {
			.centered {
				margin-bottom: 0;
			}
		}
		
		#upload_form_video_premium_options {
			display: none;
			
			&.init-ok {
				display: block;
			}
			
			legend {
				z-index: 4;
			}
		}
		
		#upload_form_video_premium_options,
		#edit_form_video_premium_options {
			#upload_form_video_premium_options_video_premium_options_centered,
			#edit_form_video_premium_options_video_premium_options_centered {
				z-index: 3;
				
				.icf-ticket-red {
					height: 124px;
					overflow: hidden;
				}
			}
			&.ppv-checked {
				#upload_form_video_premium_options_video_premium_options_centered,
				#edit_form_video_premium_options_video_premium_options_centered {
					.icf-ticket-red {
						height: 180px;
					}
				}
			}
		}
		
		#upload_form_networksites {
			&.and-network-only {
				.btn-group {
					.btn{
						&.no_restriction{
							&,
							label {
								border-bottom-right-radius: 8px;
								border-top-right-radius: 8px;
							}
						}
						&.default_only {
							display: none;
						}
					}
				}
			}
		}
		#upload_form_thumb_preview {
			&.and-preview-only {
				.btn-group {
					.btn{
						&.preview_normal{
							&,
							label {
								border-bottom-right-radius: 8px;
								border-top-right-radius: 8px;
							}
						}
						&.preview_no_preview,
						&.preview_hidden {
							display: none;
						}
					}
				}
			}
		}
		
		#upload_form_title_translations {
			& > fieldset {
				margin-bottom: 30px;
				&:last-of-type {
					margin-bottom: 15px;
				}
				.form-group {
					margin-bottom: 0;
				}
			}
		}
		
		#upload_form_sponsorlinks {
			.sponsor-links-actions {
				margin-top: -10px;
				text-align: center;
				.btn {
					white-space: normal;
				}
			}
		}
		
		.container-list-models-suggestion,
		.container-model-info-form {
			border: 1px solid @form-tag-adder-border;
			border-top: none;
			box-shadow: 0 0 5px @form-tag-adder-border;
		}
	}
	
	.circle-infos {
		.col-sm-2 {
			padding-left: 5px;
			padding-right: 5px;
		}
		#circle-info-hd-required-col {
			display: none;
		}
	}
	
	.stopwatch-info,
	.circle-info {
		.inline-block;
		font-size: 140px;
		margin: 0 0 0.1em;
		position: relative;
		width: 0.835em;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		.small,
		.big {
			color: @text-invert-color;
			.text-border-2px(@text-color);
			display: block;
			line-height: 1.1em;
			position: relative;
			text-align: center;
			word-break: break-all;
			z-index: 3;
		}
		&.text-danger {
			.small,
			.big {
				.text-border-2px(@state-danger-text);
			}
		}
		.small {
			font-size: 0.14em;
			&:first-child {
				padding-top: 0.4em;
			}
			.icf-ticket-red.icf-white-fill {
				color: @red-color;
				font-size: 1.5em;
				text-shadow: none;
			}
		}
		.big {
			font-size: 0.2em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	
	.stopwatch-info {
		height: 0.835em;
		margin-top: 0.165em;
		padding: .1em .07em;
		.icf-stopwatch {
			left: -0.0825em;
			position: absolute;
			text-align: center;
			top: -0.165em;
			width: 100%;
			z-index: 1;
			[dir="rtl"] & {
				left: auto;
				right: -0.0825em;
			}
		}
	}
	
	.circle-info {
		background: @text-color;
		border-radius: 50%;
		height: 0.835em;
		margin-top: 0.165em;
		padding: .1em .07em;
		&.text-danger {
			background: @state-danger-text;
			.small,
			.big {
				color: white;
			}
		}
		&:before {
			border: 0.015em solid @account-panel-bg;
			border-radius: 50%;
			content: " ";
			display: block;
			height: 0.765em;
			left: .035em;
			position: absolute;
			top: .035em;
			width: .765em;
			z-index: 2;
		}
	}
	
	#circle-info-min-dur {
		.big {
			font-size: 0.16em;
			line-height: 1.38em;
			& + .small {
				font-size: 0.1em;
			}
		}
	}
	
	#circle-info-no-watermarks {
		.big {
			font-size: 0.1em;
			line-height: 1.3em;
			padding-top: 1.9em;
			word-break: normal;
			strong {
				display: block;
				font-size: 1.5em;
			}
		}
	}
	
	#circle-info-no-incest {
		.big {
			font-size: 0.14em;
			padding-top: 1.15em;
			strong {
				display: block;
			}
		}
	}
	
	#circle-info-hd {
		&, * {
			cursor: pointer;
		}
		.big {
			font-size: 0.3em;
			padding-top: 0.5em;
			word-break: normal;
		}
		.icf-info-circle {
			.inline-block;
		}
		&:hover {
			.icf-info-circle {
				transition: transform 0.1s;
				transform-origin: left bottom;
				transform: scale(1.5);
			}
		}
	}
	
	#circle-info-hd-more {
		background: @account-panel-bg;
		border: 2px solid @text-color;
		border-radius: 3px;
		bottom: 0;
		display: none;
		font-size: 16px;
		padding: 5px 10px;
		position: absolute;
		z-index: 4;
		.icf-close {
			cursor: pointer;
			display: block;
			float: right;
			font-size: 20px;
			margin: -5px -10px 0 0;
			padding: 8px 8px 16px 16px;
		}
	}
	
	#circle-info-high-fps {
		.big {
			font-size: 0.15em;
			padding-top: 1.35em;
			word-break: normal;
		}
		.small {
			font-size: 0.1em;
		}
	}
	
	#circle-info-hd-required {
		.big {
			.icon-f {
				font-size: 34px;
			}
		}
	}
}

&.action-video_upload {
	.premium-infos {
		display: none;
	}
	.instructions {
		&.channel {
			.circle-infos {
				.col-xs-4 {
					width: 25%;
				}
			}
		}
		&.with-premium-infos {
			.circle-infos {
				#circle-info-hd-required-col{
					display: block;
				}
				
				.col-xs-4 {
					width: 20%;
				}
			}
			.premium-infos {
				display: inline;
			}
			.regular-infos {
				display: none;
			}
		}
	}
}

#processing-status,
#chunk-progress-container {
	.progress {
		display: block;
		font-weight: bold;
		height: 24px;
		max-width: 84%;
		margin: 0 auto;
		width: 500px;
		
		.progress-bar {
			font-size: 14px;
			line-height: 24px;
		}
	}
}

#chunk-progress-container {
	background: @body-bg;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	margin-bottom: 10px;
	padding: 10px;
	text-align: center;
	z-index: @zindex-upload-chunk-progress;

	&.fixed {
		bottom: 0;
		left: 0;
		position: fixed;
	}
	
	.status {
		padding: 5px 10px;
	}
	
	.btn {
		line-height: 16px;
		margin: 0 0 10px;
		
		.icon-f {
			float: left;
			font-size: 16px;
			margin: 0 6px 0 0;
		}
		
		&:focus {
			outline: none;
		}
	}
}
