@gold-theme:		#ffda00;

&.anonymous {
	background: #666;
	margin: 0;
	padding: 20px;
	a {
		color: #fff;
		&.link-text-color {
			color: @theme-color;
		}
	}
	.alert-danger a {
		color: @theme-color;
	}
	#page {
		position: relative;
		max-width: @screen-max;
		background: #000;
		color: #fff;
		margin: 0 auto;
		padding: 20px;
		#signin-popup-form {
			h2 {
				margin-right: 40px;
				[dir="rtl"] & {
					margin-left: 40px;
					margin-right: 0;
				}
			}
		}
		.language-switcher-row {
			position: absolute;
			top: 20px;
			left: 0;
			right: 0;
			div {
				position: relative;
				#language-switcher {
					float: right;
					cursor: pointer;
					margin-left: 0;
					margin-right: 0;
					[dir="rtl"] & {
						float: left;
						margin-left: 20px;
					}
				}
			}
			&.has-verif-msg {
				left: 30px;
				right: 30px;
			}
		}
		#language-switcher-list{
			top: 100%;
			[dir="rtl"] & {
				left: 0;
				right: auto;
			}
		}
		
		&.action-email_subscribe {
			@import "landing_newsletter.less";
		}
	}
	#footer {
		max-width: @screen-max;
		margin-left: auto;
		margin-right: auto;
		&,
		a {
			color: #eee;
		}
		.botLinks {
			text-align: center;
		}
	}
}

#main #content #account-tab-nav, //xvideos
#header #site-nav ul{ //xnxx
	li {
		.nav-tab-sub-menu {
			box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2);
			display: none;
			position: absolute;
			top: 100%;
		}
		&.with_hover_sub_menu:hover {
			.nav-tab-sub-menu {
				display: block;
			}
		}
	}
	.account-section-nav {
		a.list-group-item,
		.list-group-item a.list-line,
		.with-sub-menu > a {
			padding: 3px 6px;
		}
		.list-group-item {
			display: block;
			padding: 0;
			
			.icon-f {
				margin-right: 6px;
			}
		}
	}
}

#main {
	#content {
		@import "../../../libs/datetimepicker";
		
		//min-height: calc(~"100vh - 150px");
		.min-height-100vh(150px);
		
		#account-tab-nav {
			margin-bottom: 16px;
			padding: 8px 8px 0;
			position: relative;
			z-index: 2;
			
			li {
				margin-bottom: -1px;
				position: static;
				
				.nav-tab-sub-menu {
					background: @nav-link-hover-bg;
					padding: 10px 12px 0;
					
					.list-group-item {
						border-color: @gray-lighter;
					}
				}
				
				> a {
					color: @text-color;
					text-decoration: none;
				}
				
				&.with_hover_sub_menu:hover {
					.nav-tab-sub-menu {
						display: block;
					}
				}
			}
			
			& + .row {
				position: relative;
				z-index: 1;
			}
		}
		
		#cur-tab-nav {
			padding-top: 20px;
		}
		.account-section-title {
			display: block;
			font-size: 14px;
			font-weight: bold;
			margin: 0;
			padding: 3px 6px 3px 8px;
			text-decoration: none;
		}
		.account-section-nav {
			margin-bottom: 16px;
			a.list-group-item,
			.list-group-item a.list-line,
			.with-sub-menu > a {
				background: @account-section-nav-link-bg;
				border-color: @account-section-nav-link-border;
				color: @account-section-nav-link-color;
				font-size: 13px;
				text-decoration: none;
				&:hover {
					background: @account-section-nav-link-hover-bg;
				}
			}
			
			.list-group-item {
				border-width: 1px;
				margin-bottom: -1px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
			a.list-group-item.active,
			.list-group-item.active a.list-line,
			.with-sub-menu.active > a {
				background: @account-section-nav-link-active-bg;
			}
			.with-sub-menu {
				& > a {
					display: block;
					padding-right: 20px;
					position: relative;
					z-index: 2;
					.icf-caret-right {
						display: block;
						margin-right: 0;
						position: absolute;
						right: 4px;
						text-align: right;
						top: 7px;
						width: 10px;
					}
				}
				.list-group {
					background: @nav-link-hover-bg;
					display: none;
					left: 100%;
					padding: 8px;
					position: absolute;
					top: -8px;
					z-index: 1;
				}
				&:hover {
					& > a {
						.icf-caret-right {
							right: -8px;
						}
					}
					.list-group {
						display: block;
					}
				}
			}
		}
		#account-breadcrumb {
			margin-bottom: 20px;
		}
		.table.account-table {
			tr {
				th {
					background: @account-tab-th-bg;
					padding: 2px 5px;
					text-align: center;
					&, a {
						color: @account-tab-th-color;
					}
					a {
						display: block;
						text-decoration: none;
						.txt {
							text-decoration: underline;
						}
						& > .icon-f {
							.inline-block;
							transition: 0.2s all;
						}
						& > .icf-angle-up {
							transform-origin: center 7px;
						}
						& > .icf-angle-down {
							transform-origin: center 6px;
						}
						&:hover {
							& > .icf-angle-down,
							& > .icf-angle-up {
								transform: scale(-1.3);
							}
						}
					}
					.icf-info-circle {
						color: @account-tab-th-info-color;
					}
					&.info {
						.info-content {
							display: none;
						}
					}
				}
				td {
					padding: 7px 5px;
					&.number {
						text-align: right;
					}
					&.centered {
						text-align: center;
					}
					&.title {
						overflow: hidden;
						text-align: left;
						text-overflow: ellipsis;
					}
					.with-info,
					&.with-info {
						.info-content {
							display: none;
						}
					}
				}
				&.sub-row {
					th, td {
						padding: 3px 5px;
						font-size: @font-size-small;
						border-top: none;
						border-bottom: none;
					}
				}
			}
		}
		.table-responsive {
			margin-bottom: @line-height-computed;
			> .table {
				margin-bottom: 0;
			}
			
		}
		.content-row {
			margin: 0 0 @account-panel-padding*2;
			padding: 0 0 @account-panel-padding;
			border-bottom: 1px solid @account-panel-border;
			&.no-border {
				border-bottom: none;
			}
			.row {
				margin-left: 0;
				margin-right: 0;
				& > * {
					padding-left: 0;
					padding-right: 0;
				}
			}
			.frame {
				border: 1px solid @account-panel-border;
				background: @account-tab-td-bg;
				margin: @account-panel-padding;
				padding: @account-panel-padding;
				display: block;
				> :first-child {
					margin-left: 0;
					margin-right: 0;
					margin-top: 0;
				}
				> :last-child {
					margin-bottom: 0;
				}
			}
			&.stats-row {
				.frame {
					padding: 0;
					font-size: 14px;
					text-align: center;
					strong {
						display: block;
						font-size: 22px;
					}
				}
			}
			.form-horizontal.form-step {
				> fieldset {
					padding-left: 5px;
					padding-right: 5px;
					legend {
						margin: 0 -5px 5px;
						width: ~"calc(100% + 10px)";
					}
				}
				.form-group {
					margin-bottom: 0;
					label.control-label {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

#nav {
	.ordered-label-list {
		.btn.label.main {
			text-transform: none;
		}
		.sub-list .icon-f {
			color: @gray-light;
			margin-right: 4px;
		}
	}
}

h2.fatal-error {
	font-size: 26px;
	margin: 50px 0 40px;
	padding: 0 20px;
	text-align: center;
	.icon-f {
		float: none;
		font-size: 1.3em;
		vertical-align: sub;
	}
}

.premium-page &.anonymous {
	font-size: 1rem;
	line-height: 1.5;
	background: #787878 url("../../img/skins/default/red_form_bg.jpg") no-repeat left center;
	background-size: auto 100%;
	@media (min-aspect-ratio: ~"1917/1058") {
		background-size: 100%;
		background-position: center top;
	}
	#page {
		&.exec-manage-login_create,
		&.exec-manage-premium_result {
			background: transparent;
			padding: 0;
			max-width: 970px;
			#language-switcher {
				display: block;
				position: absolute;
				top: 0;
				right: 10px;
				cursor: pointer;
			}
		}
	}
}

.xvideos-text-color {color: @red-color;}
.xnxx-text-color {color: @gold-theme;}

.network-text-color {color: @network-blue;}

.mozaique {
	.thumb-block {
		.thumb-under {
			p {
				margin: 0;
			}
		}
	}
}