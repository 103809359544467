
@import "../default/variables";
@import "variables";
@import "../default/bootstrap";
@import "../../libs/datetimepicker";
.split-css-rule{display:none;color:#123456}
@import "../../common/xvxn_common";
@import "../../common/dragdrop_select_and_reorder";
@import "../../common/emoji";
@import "../../common/threads";
.split-css-rule{display:none;color:#123456}
@import "../default/elements";
@import "../default/page";
.split-css-rule{display:none;color:#123456}
@import "../default/modules";
@import "../default/modules/chat";
@import "../default/responsive";
@import "../default/light-theme";
@import "custom";
