
table.account-table {
	min-width: 100%;
	position: relative;
	z-index: 1;
	tr {
		th, td {
			border: 1px solid @account-tab-border;
			&.title {
				max-width: 780px;
				min-width: 280px;
			}
		}
		
		th {
			background: @account-tab-th-bg;
			background-image: linear-gradient(to top, @account-tab-th-bg, lighten(@account-tab-th-bg, 5%));
			font-size: 1.2em;
			font-weight: bold;
			padding: 2px 5px;
			text-align: center;
			
			&, a {
				color: @account-tab-th-color;
			}
			
			a {
				display: block;
				text-decoration: none;
				
				.txt {
					text-decoration: underline;
				}
				
				& > .icon-f {
					.inline-block;
					transition: 0.2s all;
				}
				
				& > .icf-angle-up {
					transform-origin: center 7px;
				}
				
				& > .icf-angle-down {
					transform-origin: center 6px;
				}
				
				&:hover {
					& > .icf-angle-down,
					& > .icf-angle-up {
						transform: scale(-1.3);
					}
				}
			}
			
			.icf-info-circle {
				color: @account-tab-th-info-color;
				vertical-align: middle;
			}
			
			&.info {
				.info-content {
					display: none;
				}
			}
		}
		td {
			background: @account-tab-td-bg;
			color: @account-tab-td-color;
			padding: 7px 5px;
			text-align: left;
			vertical-align: top;
			&.no-padding {
				padding: 0;
			}
			&.no-horiz-padding {
				padding-left: 0;
				padding-right: 0;
			}
			&.number {
				text-align: right;
			}
			&.centered {
				text-align: center;
			}
			&.title {
				overflow: hidden;
				text-align: left;
				text-overflow: ellipsis;
			}
			&.thumb {
				padding: 4px 6px;
				.btn.edit {
					background: @account-tab-td-bg;
					border: 1px solid @account-tab-btn-border;
					font-size: 16px;
					height: 24px;
					line-height: 24px;
					margin-bottom: 0;
					padding-bottom: 0;
					padding-top: 0;
					.icon-f {
						font-size: 16px;
						line-height: 22px;
					}
				}
				.casting-selected {
					color: @gray-light;
				}
			}
			.info,
			&.info {
				.info-content {
					display: none;
				}
			}
			& > .icf-ticket,
			& > .icf-check-circle,
			& > .icf-check-circle-o,
			.video-status .icon-f {
				font-size: 16px;
				vertical-align: middle;
			}
		}
		&:nth-child(2n) {
			td {
				background: @account-tab-td-even-bg;
			}
		}
		&.focused,
		&:hover {
			td {
				background: @account-tab-td-hover-bg;
			}
		}
		&.warning {
			td {
				background: @account-tab-td-warning-bg;
			}
			&:nth-child(2n) {
				td {
					background: @account-tab-td-warning-even-bg;
				}
			}
			&.focused,
			&:hover {
				td {
					background: @account-tab-td-warning-hover-bg;
				}
			}
		}
	}
	&.no-line-break,
	.no-line-break {
		white-space: nowrap;
	}
	&.condensed-table {
		tr {
			th {
				padding: 2px;
			}
			td {
				padding: 4px 2px;
				&.no-padding {
					padding: 0;
				}
			}
		}
	}
}

.overflowable-table {
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 1;
	
	table {
		position: relative;
		z-index: 1;
	}

	.overflowable-table-container {
		overflow: auto;
		width: 100%;
	}
	
	.overflowable-table-gotoleft,
	.overflowable-table-gotoright {
		bottom: 0;
		cursor: pointer;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		width: 40px;
		z-index: 2;
		.icon-f {
			color: @text-color;
			display: block;
			font-size: 36px;
			left: 0;
			margin: 2px 0;
			padding: 0;
			position: absolute;
			text-align: center;
			.text-border-2px-with-depth(@text-invert-color);
			top: 0;
			transition: 0.2s width;
			width: 40px;
			z-index: 2;
		}
		&:before {
			background: @text-invert-color;
			bottom: 0;
			box-shadow: 0 0 10px 3px @text-invert-color;
			content: ' ';
			display: block;
			left: 0;
			position: absolute;
			top: 0;
			transition: 0.2s all;
			width: 0;
			z-index: 1;
		}
		&:hover {
			.icon-f {
				width: 34px;
			}
		}
	}
	
	.overflowable-table-gotoright {
		&,
		&:before,
		.icon-f {
			left: auto;
			right: 0;
		}
	}
	
	.overflowable-table-fixed-first-row {
		margin-bottom: 0;
		position: relative;
		z-index: 3;
		th {
			border-bottom: 0;
		}
		&.is_over {
			box-shadow: 0 2px 2px rgba(0,0,0,0.5);
		}
	}
	.overflowable-table-fixed-first-col {
		left: 0;
		position: absolute;
		top: 0;
		z-index: 2;
		&.is_over {
			box-shadow: 2px 0 2px rgba(0,0,0,0.2);
		}
	}
}
