//@import "elements/base_style";
@import "elements/alerts";
@import "elements/helpers";
@import "elements/forms";
@import "elements/table";
@import "elements/bootstrap_components";
@import "elements/voters_list";
@import "../../libs/croppie.less";

#x-home-messages .x-message.x-message-warning {
	background: @brand-warning;
	border-color: @brand-warning;
}
