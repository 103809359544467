
#main {
	#uploads-video-delete {
		/*.form-horizontal.form-step {
			fieldset {
				label.control-label {
					margin: 0;
				}
			}

			legend + * {
				margin-bottom: 10px;
			}
		}

		.input-group-lg > .form-control,
		.input-group-lg > .input-group-addon,
		.input-group-lg > .input-group-btn > .btn {
			border-radius: 0;
		}

		.btn-group-lg > .btn,
		.btn-lg {
			border-radius: 0;
			color: white;
			font-weight: bold;
			background-color: #de2600;
			border-color: #c52200;
		}

		p.stats {
			margin: 20px 10px;
			font-size: 120%;
		}

		#container-list-videos {
			text-align: center;
			position: relative;
			.x-loader-inline.after {
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 2;
				background: @gray-lighter;
			}
		}

		#tabs-video {
			margin-bottom: 20px;
			li > a {
				text-decoration: none;
				color: black;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}*/

		#list-videos,
		#list-video-del {
			/*max-height: 400px;
			overflow: hidden;*/

			.actions {
				/*white-space: nowrap;

				span {
					cursor: pointer;
				}*/

				.info-hover .info-content {
					left: auto;
					right: 0;
				}
			}

			/*td {
				&.status {
					white-space: nowrap;
				}
			}*/
		}
	}
}

