
&.action-social_network_signin {
	#main {
		.social-login-icon {
			line-height: 24px;

			img {
				font-size: 24px;
				vertical-align: top;
				height: 1em;
				line-height: 1em;

				&.icon-google, &.icon-x-twitter {
					padding: 0.1em;
					line-height: 0.8em;
				}
			}
		}
	}
}