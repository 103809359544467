
h1, h2, h3, h4, h5, h6 {
	margin: 1em -0.125em 0.5em;
	.icon-f:first-child {
		float: left;
		margin-right: 0.3em;
	}
	&.text-center,
	.text-center > &{
		.icon-f:first-child {
			float: none;
		}
	}
}

h1 { font-size: 32px; }

h2 { font-size: 28px; }

h3 { font-size: 24px; }

h4 { font-size: 22px; }

h5 { font-size: 18px; }

h6 { font-size: 16px; }

.panel-body {
	
	& > h1, & > h2, & > h3, & > h4, & > h5, & > h6 {
		&:first-child {
			margin: 0.25em -.125em .75em
		}
	}
}

a {
	cursor: pointer;
	text-decoration: underline;
	&.btn,
	&:hover {
		text-decoration: none;
	}
	&.btn-link:hover {
		text-decoration: underline;
	}
	&.no-color {
		color: @text-color;
	}
	&.no-underline {
		text-decoration: none;
	}
}

.btn.btn-wrap {
	white-space: normal;
}

ul, p, dl {
	font-size: 14px;
	a {
		color: @account-panel-link-color;
	}
}

.tb-menu {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

ul {
	list-style: disc outside;
	margin: 1.5em 0;
	padding-left: 4em;
	li {
		margin: 0 0 0.2em;
		font-size: 1em;
	}
	&.list-unstyled {
		padding-left: 0;
		list-style: none;
	}
	&.icon-f-list {
		li {
			list-style: none;
			position: relative;
			&:before {
				.font-family-iconfont();
				display: block;
				font-size: 1em;
				left: -3em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 3em;
			}
			&:not([class^="icf-"]) {
				&:before {
					content: @icf-caret-right;
				}
			}
		}
		&.icfl-angle-double-right li:before {	content: @icf-angle-double-right; }
		&.icfl-angle-right li:before {			content: @icf-angle-right;	}
		&.icfl-arrow-right li:before {			content: @icf-arrow-right;	}
		&.icfl-ban li:before {					content: @icf-ban;	}
		&.icfl-caret-right li:before {			content: @icf-caret-right;	}
		&.icfl-check li:before {				content: @icf-check;	}
		&.icfl-check-circle li:before {			content: @icf-check-circle;	}
		&.icfl-check-circle-o li:before {		content: @icf-check-circle-o;	}
		&.icfl-check-square li:before {			content: @icf-check-square;	}
		&.icfl-close li:before {				content: @icf-close;	}
		&.icfl-close-circle li:before {			content: @icf-close-circle;	}
		&.icfl-comment-o li:before {			content: @icf-comment-o;	}
		&.icfl-envelop li:before {				content: @icf-envelop;	}
		&.icfl-eye li:before {					content: @icf-eye;	}
		&.icfl-info-circle li:before {			content: @icf-info-circle;	}
		&.icfl-question-circle-o li:before {	content: @icf-question-circle-o;	}
		&.icfl-warning li:before {				content: @icf-warning;	}
		&.icfl-warning-circle li:before {		content: @icf-warning-circle;	}
	}
}

.pagination {
	ul {
		margin: 0;
		padding: 0;
		li {
			margin: 0 5px;
		}
	}
}

table ul {
	margin: 0;
	font-size: @font-size-base;
}

p {
	margin: 0 0 0.5em;
	&.form-control-static {
		margin-bottom: 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}
