
@import "bootstrap";
@import "../../libs/datetimepicker";
@import "variables";
@import "../../common/faq_search";

.premium-page {
	font-size: 1rem;
	min-height: 100%;
}

body {
	overflow-y: auto;
	
	&.upload-publish-results {
		overflow: hidden;
		padding: 0;
		margin: 0;
	}
	&:after,
	&:before{
		content: none;
		display: none;
	}
	@media (min-width: @screen-xlg-min) {
		padding-left: @body-out-padding;
		padding-top: @body-out-padding/2;
		padding-right: @body-out-padding;
		width: 100%;
	}
	&.mobile-menu-opened {
		@media @media-ratio-horizontal, (min-width: @screen-sm-min) {
			overflow: hidden;
			position: fixed;
		}
	}
}


/* min 768px - view desktop */
@media (min-width: @screen-sm-min) {
	body:not(.body--with-old-header) {
		.width-full-body {
			margin: 0 -@body-out-padding;
			width: calc(~"100% + 2 * @{body-out-padding}");
		}
		
		.width-main-container {
			max-width: @screen-xlg-max;
			padding: 0 4px;
			
			&.head__menu-line__container {
				position: relative;
				
				#nav {
					top: 0;
				}
			}
		}
	}
}

.xv-responsive #page.account-page{
	max-width: @screen-xlg-max;
}


.faq-search-results {
	padding: 0;
}

.support-debug-popup {
	.x-body .x-content .contextual-popup-message {
		font-size: 11px;
	}
}

@import "elements/base_style";
@import "elements";
.account-page {
	@import "modules";
	@import "responsive";
}
