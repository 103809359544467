@import "../../../common/form/tag_adder";
@import "../../../common/form/models_selector";
@keyframes input_error_focus_inner {
	0% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
	5% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 40%) inset;
	}
	40% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	80% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 20%) inset;
	}
	100% {
		box-shadow: 0 0 0 1000px fadeout(@red-color, 100%) inset;
	}
}

#main {
	.form-horizontal {
		&.form-bordered {
			border: 1px solid @account-panel-border;
			background: @account-tab-td-bg;
			padding: @account-panel-padding;
			> :first-child {
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
			}
			> :last-child {
				margin-bottom: 0;
			}
		}
		&.has-read-only {
			.form-group .content,
			.btn-edit-only {
				display: none;
			}
			&.editing {
				.form-group .content {
					display: block;
				}
				.btn-edit-only {
					display: inline-block;
				}
				.form-group .content-read-only,
				.btn-read-only {
					display: none;
				}
			}
		}
		#btn-content-owner {
			margin-top: 20px;
		}
		.control-label {
			font-size: 14px;
			font-weight: normal;
			text-align: left;
			[dir="rtl"] & {
				text-align: right;
			}
		}
		.checkbox label {
			font-size: 14px;
			&.text-danger a {
				color: @alert-danger-text;
				font-weight: bold;
			}
		}
		.checkbox.toggle {
			margin-left: 15px;
		}
		textarea {
			min-height: 70px;
			resize: vertical;
		}
		.help-block,
		.error-block {
			font-size: 13px;
		}
		.help-block:not(.error-block) {
			color: lighten(@text-color, 25%);
		}
		&.form-step {
			&.highlight-errors {
				fieldset.centered {
					.form-group {
						&.validator-error {
							&.hl-focus {
								.btn-group {
									.btn {
										label {
											animation: input_error_focus_inner 1.5s ease-out;
										}
									}
								}
							}
						}
					}
				}
			}
			fieldset {
				font-size: 16px;
				padding-bottom: 2.75 * @account-panel-padding;
				position: relative;
				fieldset {
					padding-bottom: 0;
				}
				label.control-label {
					display: block;
					font-weight: bold;
					margin: 0 0 8px 16px;
					[dir="rtl"] & {
						margin: 0 16px 8px 0;
					}
					.details {
						display: block;
						font-weight: normal;
						&:first-letter {
							text-transform: uppercase;
						}
					}
					.optional {
						font-weight: normal;
						font-style: italic;
					}
				}
				.checkbox label {
					font-size: 16px;
				}
				.has-floating-count {
					margin-bottom: 20px;
				}
				.floating-count {
					position: absolute;
					top: 100%;
					right: 0;
					font-size: 11px;
					line-height: 20px;
					padding: 0 15px 0 2px;
					transition: 0.5s opacity;
					&.empty {
						opacity: 0;
					}
					.count {
						font-weight: bold;
					}
					[dir="rtl"] & {
						left: 0;
						padding: 0 2px 0 15px;
						right: auto;
					}
				}
				&.centered {
					text-align: center;
					.form-group {
						label.control-label {
							margin-left: 0;
							text-align: center;
						}
						.btn-group,
						.checkbox-list {
							display: inline-block;
							float: none;
							width: auto;
						}
						.btn-group {
							.btn {
								&:first-child {
									border-bottom-left-radius: 8px;
									border-top-left-radius: 8px;
								}
								&:last-child {
									border-bottom-right-radius: 8px;
									border-top-right-radius: 8px;
								}
								label {
									input {
										&::before,
										&::after {
											border-radius: 50%;
										}
									}
								}
							}
							&.btn-disjoint {
								text-align: center;
								.btn {
									border-radius: 8px;
									float: none;
									margin: 0 @account-panel-padding/2 @account-panel-padding;
									label {
										border-radius: 8px;
									}
								}
								.ticket-red-height(50px);
							}
						}
						.btn-group.btn-disjoint,
						.checkbox-list {
							.btn.ticket-red {
								background: transparent;
								border-color: transparent;
								border-radius: 0;
								label {
									background-color: transparent;
									border-radius: 0;
									input {
										z-index: 3;
										&::after {
											background: @form-horizontal-radio-inp-premium-act-color;
											color: @form-horizontal-radio-inp-premium-act-bg;
											.text-border-2px(@form-horizontal-radio-inp-premium-act-color);
										}
										&:checked + .icf-check-circle {
											opacity: 1;
											transform: initial;
											transition: all 0.2s;
										}
									}
									.icf-check-circle {
										background: @red-color;
										color: white;
										font-size: 16px;
										left: 17px;
										opacity: 0;
										position: absolute;
										top: 17px;
										transform: scale(2);
										z-index: 4;
									}
								}
								.ticket-in {
									font-size: 16px;
								}
							}
							.ticket-red-height(50px);
						}
					}
				}
				&.centered .form-group .btn-group .btn,
				.checkbox.btn {
					border-color: @form-horizontal-radio-btn-border;
					padding: 0;
					label {
						background-color: @form-horizontal-radio-btn-bg;
						font-size: 16px;
						height: 50px;
						line-height: 50px;
						padding: 0 15px 0 40px;
						transition: all 0.15s;
						&:hover {
							box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
						}
						&:focus,
						&:active {
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
							transform: translateY(1px);
						}
						input {
							border: 2px solid @form-horizontal-radio-inp-border;
							cursor: pointer;
							height: 14px;
							left: 18px;
							margin: -7px 0 0;
							position: absolute;
							top: 50%;
							width: 14px;
							z-index: 1;
							&::before,
							&::after {
								background: @form-horizontal-radio-inp-bg;
								content: " ";
								cursor: pointer;
								display: block;
								position: absolute;
							}
							&::before {
								border: 2px solid @form-horizontal-radio-inp-border;
								height: 14px;
								left: 50%;
								margin: -7px;
								top: 50%;
								width: 14px;
								z-index: 2;
							}
							&::after {
								.font-family-iconfont;
								opacity: 0;
								text-align: center;
								transform: scale(2);
								z-index: 3;
							}
							&:checked::after {
								opacity: 1;
								transform: initial;
								transition: all 0.2s;
							}
						}
						
						.checkbox-error-box {
							z-index: 2;
						}
					}
				}
				.checkbox.btn {
					margin-bottom: 4px;
					label {
						input {
							&::after {
								background: @form-horizontal-radio-inp-act-bg;
								color: @form-horizontal-radio-inp-act-color;
								border: 2px solid @form-horizontal-radio-inp-act-border;
								content: @icf-check;
								font-size: 14px;
								height: 18px;
								line-height: 14px;
								left: 50%;
								margin: -9px;
								top: 50%;
								width: 18px;
							}
							&:disabled::after {
								background: @form-horizontal-radio-inp-inact-bg;
								color: @form-horizontal-radio-inp-inact-color;
								border: 2px solid @form-horizontal-radio-inp-inact-border;
							}
						}
					}
				}
				&.centered .form-group .btn-group .btn {
					&:first-child {
						label {
							border-bottom-left-radius: 8px;
							border-top-left-radius: 8px;
						}
					}
					&:last-child {
						label {
							border-bottom-right-radius: 8px;
							border-top-right-radius: 8px;
						}
					}
					label {
						input {
							&::after {
								background: @form-horizontal-radio-inp-act-color;
								border-radius: 50%;
								color: @form-horizontal-radio-inp-act-bg;
								content: @icf-check-circle;
								font-size: 16px;
								height: 16px;
								line-height: 16px;
								left: 50%;
								margin: -8px;
								.text-border-2px(@form-horizontal-radio-inp-act-color);
								top: 50%;
								width: 16px;
							}
							&:disabled::after {
								background: @form-horizontal-radio-inp-inact-color;
								color: @form-horizontal-radio-inp-inact-bg;
								.text-border-2px(@form-horizontal-radio-inp-inact-color);
							}
						}
					}
				}
				&.files {
					margin-top: 50px;
				}
				&.overlap-x-popup {
					z-index: 3;
					overflow: visible;
				}
			}
			legend {
				background: @form-horizontal-legend-bg;
				border-bottom: 0;
				color: @text-color;
				float: left;
				font-size: 18px;
				padding: @account-tabs-padding;
				& + * {
					clear: both;
				}
				.counter {
					.inline-block;
					background: black;
					border-radius: 50%;
					color: white;
					font-size: 12px;
					font-weight: bold;
					height: 32px;
					line-height: 32px;
					margin-right: @account-tabs-padding;
					text-align: center;
					width: 32px;
				}
				.optional {
					color: @gray;
					font-size: 16px;
					font-style: italic;
				}
				.clear-link{
					text-decoration: none;
					.icon-f{
						vertical-align: middle;
					}
				}
			}
			.input-group {
				.fa {
					z-index: 3;
				}
				&.language-select {
					position: static;
					.input-group-btn {
						vertical-align: top;
						.btn {
							border-color: @input-border;
							margin-bottom: 0;
							padding: 0 10px;
							transition: all 0.2s;
							position: relative;
							.flag {
								display: block;
								transition: transform 0.2s;
							}
							.lang-selector-icon {
								font-size: 20px;
								display: block;
								transition: transform 0.2s;
								&.icon-f {
									line-height: 32.5px;
								}
								&.flag.flag-question {
									margin: 6px 0;
								}
							}
							.flag-detected {
								position: absolute;
								right: 2px;
								bottom: 2px;
								&.flag-question {
									background: @theme-color;
								}
							}
							&:active,
							&:focus {
								outline: none;
							}
							&:active {
								box-shadow: 0 2px 4px 2px rgba(0, 0, 0, .3) inset;
								.flag,
								.lang-selector-icon {
									opacity: 0.8;
									transform: translateY(2px);
								}
							}
						}
						&.trash-btn {
							.btn {
								font-size: 16px;
								line-height: 32px;
								border-bottom-left-radius: 0;
								border-top-left-radius: 0;
								border-left: none;
								.icon-f {
									vertical-align: text-top;
								}
							}
						}
					}
					.form-control {
						min-height: 34px;
					}
					textarea.form-control {
						min-height: 70px;
					}
				}
				.help-block,
				.error-block {
					display: table-row;
				}
			}
			.input-pwd-check {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}
			.progress-pwd-check {
				border-radius: 0;
				margin-bottom: 0;
				height: 4px;
			}
			.x-popup.form-popup-error {
				&.above {
					.x-popup-arrow {
						border-top-color: @theme-color;
					}
				}
				&.below {
					.x-popup-arrow {
						border-bottom-color: @theme-color;
					}
				}
				.x-popup-content {
					background: @theme-color;
					box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
					padding: 2px 8px;
					word-break: break-word;
					.click-func {
						cursor: pointer;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
			button.submit {
				display: block;
				font-size: 18px;
				margin: 0 auto;
				padding: 16px 30px;
				white-space: normal;
				&:active,
				&:focus {
					outline: none;
				}
			}
		}
	}
	
	.form-error-list-contener {
		.form-error-list {
			margin: 0.8em 0;
		}
	}
}

.panel {
	.select-label {
		background: @account-panel-bg;
	}
}

select,
button {
	&.success {
		animation: input_saved 1.5s ease-out;
		border-color: @account-video-status-success-color;
	}
	&.error {
		animation: input_notsaved 1s ease-out;
		border-color: @account-video-status-danger-color;
	}
	&.wait {
		cursor: wait;
	}
}

.animation_pulse(@anim_pulse_color,@anim_pulse_bg_percent) {
	0% {
		box-shadow: 0 0 0 0 fadeout(@anim_pulse_color, 40%);
		background: fadeout(@anim_pulse_color, min(100%, @anim_pulse_bg_percent + 30%));
	}
	18% {
		background: fadeout(@anim_pulse_color, @anim_pulse_bg_percent);
	}
	35% {
		box-shadow: 0 0 5px 25px fadeout(@anim_pulse_color, 100%);
	}
	50% {
		box-shadow: 0 0 0 0 fadeout(@anim_pulse_color, 100%);
	}
	80% {
		background: fadeout(@anim_pulse_color, @anim_pulse_bg_percent);
	}
}

@keyframes input_saved {
	.animation_pulse(@account-video-status-success-color, 70%);
}

@keyframes input_notsaved {
	.animation_pulse(@account-video-status-danger-color, 70%);
}

.locale-selector-popup {
	margin-right: 30px;
	.x-popup-arrow {
		border-bottom-color: @logo-bg;
		left: 22px;
		margin-left: 0;
	}
	.x-popup-content {
		background: @logo-bg;
		padding: 10px;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				padding: 0;
			}
			a {
				color: white;
				display: block;
				font-size: 14px;
				height: 24px;
				line-height: 24px;
				overflow: hidden;
				padding: 0 2*@account-panel-padding;
				text-decoration: none;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:hover {
					background: black;
					transition: all 0.2s;
				}
			}
		}
	}
}

.content-partener-warning {
	color: @red-color;
	margin: 0;
	padding-bottom: 7px;
	padding-top: 7px;
	
	& + .content-partener-warning {
		padding-top: 0;
	}
}

#update-email-prefs_prefs_marketing {
	position: relative;
	margin: 20px 0;
	padding: 20px 15px;
	background: #000000;
	color: #FFFFFF;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-end;
	// set to 'visible' after resizing is done properly in js
	visibility: hidden;
	h3 {
		position: absolute;
		top: 50%;
		left: 0;
		order: 1;
		width: 50%;
		max-height: 100%;
		margin: 0;
		margin-top: 20px;
		padding-bottom: 20px;
		text-align: center;
		line-height: 1;
		transform: translateY(-50%);
		div {
			.marketing-content {
				display: block;
				padding-left: 5px;
				text-transform: uppercase;
				font-weight: normal;
				font-size: 23px;
				line-height: 1;
				span {
					display: block;
					margin-left: -5px;
					font-weight: bold;
					font-size: 120px;
					line-height: 0.8;
				}
			}
			.logo {
				direction: ltr;
				display: block;
				margin: 3px 0 0;
				svg {
					margin-bottom: 2px;
					margin-right: -5px;
				}
				.icf-ticket-red {
					font-size: 58px;
					line-height: 0.60;
					color: #DE2600;
				}
			}
		}
	}
	.marketing-mentions {
		width: 50%;
		order: 3;
		text-transform: uppercase;
		
		p {
			font-size: 11px;
		}
	}
	.form-group {
		order: 2;
		width: 50%;
		margin-left: 0;
		margin-right: 0;
		padding-right: 15px;
		.checkbox.toggle {
			input {
				&:checked {
					& + label {
						&:before {
							background: @red-color;
							border-color: @toggle-border-focus;
						}
					}
				}
			}
			label {
				&:before {
					background: #333333;
					border: 1px solid #333333;
				}
			}
		}
	}
}

html[dir="rtl"] & #update-email-prefs_prefs_marketing {
	align-items: flex-start !important;
}