.faq-search-results {
	padding-left: 20px;
	> li {
		margin-top: 15px;
	}
	.faq-search-container {
		position: relative;
		.faq-search-result-text {
			h4 {
				font-weight: normal;
				font-size: 17px;
			}
			.flag-small {
				cursor: pointer;
				margin-left: 4px;
			}
			.faq-search-hide {
				display: none;
			}
			em {
				font-weight: bold;
			}
		}
		.faq-search-result-score {
			display: inline-block;
			float: right;
			margin-left: 10px;
			.faq-search-stars-container {
				height: 16px;
				position: relative;
				.faq-search-stars-back,
				.faq-search-stars-front {
					font-size: 14px;
					height: 16px;
					color: #706b62;
					span {
						margin-right: 1px;
					}
				}
				.faq-search-stars-front {
					position: absolute;
					top: 0;
					z-index: 2;
					color: #ffc85b;
					overflow: hidden;
				}
			}
		}
	}
}