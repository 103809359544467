#review-list-model {
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: 1 0 21%; /* Carte par ligne */
    .loading-text {
      margin: 0 auto;
      padding: 20px;
      text-align: center;
      font-size: 30px;
    }
    .title {
      text-align: center;
    }
  }
  .title {
    text-align: center;
  }
  .model-count {
    color: @text-color;
    font-size: 16px;
  }
}
