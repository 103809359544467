.reporter-notice-details,
.information-request-details,
.information-requests-wrapper,
.content-removals-wrapper {
  font-size: 13px;

  table td {
    text-align: left;

    &.action {
      white-space: nowrap;
    }
  }
  .gallery-online,
  .video-online,
  .status-pending {
    color: orange;
  }
  .status-rejected {
    color: @red-color;
  }
  .gallery-deleted,
  .video-deleted,
  .status-processed,
  .status-removed {
    color: @brand-success;
  }
  .request-data {
    margin: 30px 0;
  }
}

.reporter-notice-details table.table table {
  tr {
    &:not(:first-child) {
      td {
        border-top: 1px solid @gray-lighter;
      }
    }
    td {
      padding: 4px 0;

      .btn {
        margin: 4px 0 4px 10px;
      }
    }
  }
}