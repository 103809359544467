
#main {
	#friendsrequests-actions {
		margin-bottom: 20px;
		overflow: hidden;
		width: 100%;
		
		& > div {
			float: left;
			text-align: center;
			
			& + div {
				margin-left: 40px;
			}
			
			.label {
				color: @text-color;
				font-size: 14px;
				.inline-block;
				padding-right: 4px;
			}
			
			.btn {
				&:active, &:focus {
					outline: none;
				}
			}
			
			.btn-group {
				.btn {
					&:first-child {
						border-top-left-radius: 2px;
						border-top-right-radius: 2px;
					}
					
					&:last-child {
						border-bottom-left-radius: 2px;
						border-bottom-right-radius: 2px;
					}
				}
			}
		}
		
		#friendsrequests-check-all {
			.icon-f {
				.text-border-1px(@form-horizontal-checkbox-icf-color);
				background: @form-horizontal-checkbox-icf-color;
				border-radius: 4px;
				color: @form-horizontal-checkbox-icf-bg;
				margin-right: 2px;
				position: relative;
				top: 1px;
			}
			
			&:hover .icf-square,
			.icf-minus-square,
			.icf-check-square {
				display: none;
			}
			
			.icf-square,
			&:hover .icf-check-square {
				.inline-block;
			}
			
			&.all {
				.icf-square,
				.icf-minus-square,
				&:hover .icf-check-square {
					display: none;
				}
				
				&:hover .icf-square,
				.icf-check-square {
					.inline-block;
				}
			}
			
			&.partial {
				.icf-square,
				&:hover .icf-minus-square,
				.icf-check-square {
					display: none;
				}
				
				&:hover .icf-square,
				.icf-minus-square {
					.inline-block;
				}
			}
		}
		
		#friendsrequests-accept {
			&:hover {
				.icon-f {
					color: @state-success-text;
				}
			}
		}
		
		#friendsrequests-refuse {
			.icons {
				position: relative;
				display: inline-block;
				width: 24px;
				.icon-f {
					font-size: 18px;
					&.small-icf {
						font-size: 12px;
					}
				}
			}
			&:hover {
				.icon-f {
					color: @state-danger-text;
				}
			}
		}
	}
	
	.mozaique.profileslist {
		.thumb-block {
			&.checked a {
				border-color: @thumb-block-thumb-selected-border;
			}
			
			.friend-request-actions {
				overflow: hidden;
				
				label,
				button {
					.button-variant(@text-color, @btn-default-bg, @thumb-block-thumb-border) ;
					border-width: 0;
					cursor: pointer;
					display: block;
					float: left;
					height: 30px;
					line-height: 34px;
					margin: 0;
					padding: 0;
					width: 33.33%;
					
					&:focus, &:active {
						outline: none;
					}
				}

				button.full {
					width: 66.66%;
				}

				button {
					&.accept:hover {
						color: @state-success-text;
					}
					
					&.refuse:hover {
						color: @state-danger-text;
					}
				}
				
				label {
					line-height: 30px;
					
					input {
						border-width: 2px;
						border-color: @form-horizontal-checkbox-icf-color;
						.inline-block;
						height: 12px;
						position: relative;
						vertical-align: middle;
						width: 12px;
						z-index: 1;
						
						&::before,
						&::after {
							.font-family-iconfont;
							.text-border-1px(@form-horizontal-checkbox-icf-color);
							background: @form-horizontal-checkbox-icf-color;
							border-radius: 4px;
							color: @form-horizontal-checkbox-icf-bg;
							display: block;
							font-size: 14px;
							height: 14px;
							left: -1px;
							line-height: 14px;
							position: absolute;
							text-align: center;
							top: -1px;
							transition: 0.2s;
							width: 14px;
						}
						
						&::before {
							content: @icf-square;
							z-index: 2;
						}
						
						&::after {
							content: @icf-check-square;
							opacity: 0;
							transform: scale(3);
							z-index: 3;
						}
						
						&:checked {
							&::before {
								opacity: 0;
							}
							
							&::after {
								opacity: 1;
								transform: scale(1);
							}
						}
					}
				}
			}
		}
	}
	
	.accept-all .mozaique.profileslist .thumb-block,
	.accept-all-page .mozaique.profileslist .thumb-block,
	.accept-checked .mozaique.profileslist .thumb-block.checked {
		&,
		&.checked {
			.thumb a {
				border-color: @state-success-text;
			}
		}
		
		.profile-name a,
		.profile-info {
			color: @state-success-text;
		}
	}
	
	.accept-all-page .pagination ul li a.active,
	.accept-all .pagination ul li a {
		border-color: @state-success-text;
	}
	
	.refuse-all .mozaique.profileslist .thumb-block,
	.refuse-all-page .mozaique.profileslist .thumb-block,
	.refuse-checked .mozaique.profileslist .thumb-block.checked {
		&,
		&.checked {
			.thumb a {
				border-color: @state-danger-text;
			}
		}
		
		.profile-name a,
		.profile-info {
			color: @state-danger-text;
		}
	}
	
	.refuse-all-page .pagination ul li a.active,
	.refuse-all .pagination ul li a {
		border-color: @state-danger-text;
	}
	
	#profile-activities {
		margin-top: 8px;
	}
}

&.action-friends_list {
	#main {
		.small-icf {
			position: absolute;
			left: 52%;
			top: 52%;
			font-size: 12px;
			text-shadow: 1px 1px 0 #e6e6e6, -1px -1px 0 #e6e6e6, 1px -1px 0 #e6e6e6, -1px 1px 0 #e6e6e6;
		}
		.mozaique.profileslist {
			.thumb-block {
				.friend-request-actions {
					label,
					button {
						position: relative;
						width: 50%;
						.icon-f {
							font-size: 16px;
							&.small-icf {
								font-size: 11px;
							}
						}
					}
				}
			}
		}
	}
}

#profile-walls-list {
	.thumb-block {
		margin-right: 10px;
	}
	.wall-comments {
		overflow: hidden;
	}
	
	.thumbs-small {
		background: @text-invert-color;
		border: 1px solid @account-panel-border;
		margin-bottom: @account-panel-padding;
		padding: @account-panel-padding;
	}
	
	.thread-node-children-nodes {
		.thread-node {
			background: @text-invert-color;
			border: 1px solid @account-panel-border;
			margin-bottom: @account-panel-padding;
			padding: @account-panel-padding;
			overflow: hidden;
		}
	}
}