
&.exec-uploads-video_detailed_statistics,
&.exec-uploads-video_statistics {
	
	#main-big-stats {
		border-bottom: 1px solid @account-panel-border;
		margin: 0 0 @account-panel-padding*2;
		padding: 0 0 @account-panel-padding;
		
		.row {
			margin-left: 0;
			margin-right: 0;
			
			& > * {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		.frame {
			border: 1px solid @account-panel-border;
			background: @account-tab-td-bg;
			display: block;
			font-size: 14px;
			margin: @account-panel-padding;
			text-align: center;
			
			strong {
				display: block;
				font-size: 22px;
			}
		}
	}
	
	#uploader-stats-container {
		position: relative;
		margin: @account-panel-padding*4 0 0;
		padding: @account-panel-padding 0 0;
		&.uploader-stats-pies-bottom {
			border-top: 1px solid @account-panel-border;
		}
	}
	
	#content #uploader-stats-container .content-row:last-child {
		border-bottom: 0;
		margin-bottom: 0;
	}
	
	#video-tab-customize {
		padding: 0 @account-panel-padding/2;
		position: relative;
		z-index: 2;
		
		> .btn.table-filters-toggle {
			float: left;
		}
		
		&.opened {
			margin-bottom: @account-panel-padding;
			
			> .btn.table-filters-toggle {
				float: none;
			}
		}
		
		.overflowable-table-filters {
			display: none;
			padding: @account-panel-padding/2 @account-panel-padding 0;
		}
		
		.filter {
			.inline-block;
			margin: 2px;
			padding: 0;
			position: relative;
			
			label {
				&, * {
					cursor: pointer;
				}
				
				border: 1px solid @account-tab-border;
				border-radius: 3px;
				color: @text-color;
				font-size: 14px;
				line-height: 18px;
				padding: 3px 8px 2px 30px;
				position: relative;
				z-index: 2;
				
				.custom-checkbox {
					left: 0;
					position: absolute;
					text-align: center;
					top: 0;
					width: 30px;
					
					.icon-f {
						background: @account-panel-bg;
						border-radius: 4px;
						display: none;
						font-size: 13px;
						line-height: 23px;
					}
					
					.icf-check-circle {
						color: @state-success-text;
					}
					
					.icf-close-circle {
						color: @state-danger-text;
					}
				}
			}
			
			input[type='checkbox'] {
				left: 7px;
				position: absolute;
				top: 7px;
				z-index: 1;
				
				&:checked + label .custom-checkbox .icf-check-circle {
					display: block;
				}
				
				&:not(:checked) + label .custom-checkbox .icf-close-circle {
					display: block;
				}
			}
		}
		
		button {
			line-height: 18px;
			white-space: normal;
			
			.icon-f {
				font-size: 16px;
				margin-right: 4px;
				vertical-align: text-bottom;
			}
			
			.icf-eye-blocked {
				display: none;
			}
			
			&:focus,
			&:active {
				outline: none;
			}
		}
		
		#full-width-btn {
			.icon-f {
				float: left;
				font-size: 10px;
				left: 0;
				line-height: 18px;
				position: relative;
				transition: left 0.2s;
			}
			
			.icf-arrow-right {
				float: right;
				margin-left: 4px;
				margin-right: 0;
			}
			
			&:hover {
				.icf-arrow-left {
					left: -4px;
				}
				
				.icf-arrow-right {
					left: 4px;
				}
			}
		}
		
		&.opened {
			button {
				.icf-eye {
					display: none;
				}
				
				.icf-eye-blocked {
					display: inline;
				}
			}
		}
	}
	
	.tab-full-width {
		.tab-full-width-0 {
			display: none;
		}
		
		.tab-full-width-12 {
			width: 100%;
		}
		
		#video-tab-customize {
			#full-width-btn {
				.icf-arrow-left {
					float: right;
					margin-left: 4px;
					margin-right: 0;
				}
				
				.icf-arrow-right {
					float: left;
					margin-left: 0;
					margin-right: 4px;
				}
			}
		}
	}
	
	table {
		&.with-stats {
			tr {
				th {
					white-space: nowrap;
					padding: 4px 3px;
					
					&.views-earnings {
						a:first-of-type {
							float: left;
							width: 50%;
							clear: left;
						}
					}
				}
			}
		}
	}
	
	table#video-tab-list {
		tr {
			th, td {
				white-space: nowrap;
				padding: 4px 3px;
			}
			td.premium-status {
				.icf-sheer-circle {
					vertical-align: middle;
				}
				.icf-ticket {
					font-size: 24px;
				}
			}
			td.promo-links {
				white-space: normal;
			}
		}
		&.with-stats {
			tr {
				td {
					background: @account-tab-td-bg;
					&.title {
						white-space: normal;
						word-break: break-word;
						max-width: 400px;
					}
					&.views,
					&.earnings {
						padding: 1px 2px;
						color: @gray-light;
						text-align: center;
						vertical-align: bottom;
						&.number {
							text-align: right;
						}
						small {
							font-size: 85%;
						}
					}
					&.no-stats {
						background: darken(@account-tab-td-bg, 20%);
					}
					&.promo-links-not-empty {
						min-width: 180px;
					}
					&.promo-links {
						& > div {
							cursor: pointer;
							overflow: hidden;
							line-clamp: 3;
							-webkit-line-clamp: 3;
							display: block;
							display: -webkit-box;
							-webkit-box-orient: vertical;
						}
						&.no-line-clamp {
							& > div {
								overflow: visible;
								line-clamp: initial;
								-webkit-line-clamp: initial;
							}
						}
					}
				}
				&:nth-child(4n) td,
				&:nth-child(4n+3) td {
					background: @account-tab-td-even-bg;
					&.no-stats {
						background: darken(@account-tab-td-even-bg, 20%);
					}
				}
			}
		}
		.thumb-video-stats {
			padding: 0;
			width: 80px;
			img {
				width: 80px;
			}
		}
	}
	
	#account-content-block .x-popup.stats-info-pop {
		opacity: 0.85;
		
		.x-popup-content {
			background: black;
			color: white;
			font-size: 14px;
			line-height: 18px;
			max-width: 280px;
			padding: 6px 10px;
		}
		
		.x-popup-arrow {
			border-top-color: black;
		}
	}
	
	#video-list-bulk-actions {
		margin: 8px 0 8px 4px;
		.arrow label {
			font-size: 20px;
			color: @gray;
		}
		button.btn {
			margin-bottom: 0;
		}
	}
	
}