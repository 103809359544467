//max 1800px
@media(max-width: 1800px) {
	#cur-tab-nav{
		display: none;
	}
	#cur-section-pan{
		width: 100%;
	}
}

//max 1439px
@media(max-width: @screen-lg-max) {
	table#video-tab-list tr{
		th, td {
			&.title{
				max-width: 460px;
			}
		}
	}
	&.action-video_detailed_statistics {
		table#video-tab-list tr{
			th, td {
				&.title{
					max-width: 200px;
				}
			}
		}
	}
}

//max 1360px
@media (max-width: 1360px) {
	&.anonymous {
		#page {
			&.action-email_subscribe {
				.banner-content {
					.marketing-content {
						font-size: 30px;
					}
					
					.logo {
						margin-bottom: 40px;
						
						svg {
							&,
							& > image {
								width: 220px;
								height: 55px;
							}
						}
						.icf-ticket-red {
							font-size: 61px;
						}
					}
					.marketing-mentions {
						p {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}

//max 1199px
@media(max-width: @screen-md-max) {
	table#video-tab-list tr{
		th, td {
			&.title{
				max-width: 400px;
			}
		}
	}

	#update-email-prefs_prefs_marketing {
		h3 {
			div {
				.marketing-content {
					font-size: 20px;
					span {
						font-size: 100px;
					}
				}
				.logo {
					svg {
						margin-bottom: 0;
						&,
						& > image {
							width: 138px;
							height: 47px;
						}
					}
					.icf-ticket-red {
						font-size: 53px;
					}
				}
			}
		}
	}
	
	&.anonymous {
		#page {
			&.action-email_subscribe {
				.banner-content {
					.marketing-content {
						font-size: 24px;
					}
					
					.logo {
						margin-bottom: 30px;
						
						svg {
							&,
							& > image {
								width: 172px;
								height: 43px;
							}
						}
						.icf-ticket-red {
							font-size: 51px;
						}
					}
					.marketing-mentions {
						p {
							font-size: 15px;
						}
					}
				}
			}
		}
	}
	
	html[lang="pt"] & {
		
		#update-email-prefs_prefs_marketing {
			h3 {
				div {
					.marketing-content {
						font-size: 18px;
						span {
							font-size: 55px;
						}
					}
				}
			}
		}
		
	}
}

//max 1023px
@media (max-width: 1023px) {
	&.section-uploads #video-tab-customize {
		.title-filter-container {
			max-width: none;
			float: none;
			margin-right: 0;
		}
	}
	
	#update-email-prefs_prefs_marketing {
		h3,
		.marketing-mentions,
		.form-group {
			width: 100%;
		}
		h3 {
			position: relative;
			top: auto;
			left: auto;
			transform: none;
		}
	}
}

//max 991px
@media(max-width: @screen-sm-max) {
	.panel.news,
	.panel.news.opened {
		.panel-body {
			max-height: none;
		}
	}
	.panel.news.opened {
		.more-than-2-news {
			display: block;
			letter-spacing: 2px;
			&:hover {
				letter-spacing: 0;
			}
		}
	}
	#recently-deleted-videos #deleted-videos,
	#recently-replacement-videos #replacement-videos {
		p {
			.danger {
				text-align: left;
			}
		}
	}
	#dashboard {
		.row {
			&.vertical-order {
				display: flex;
				flex-direction: column;
				.col-md-4 {
					order: 4;
					&.vertical-order-1 {
						order: 1;
					}
					&.vertical-order-2 {
						order: 2;
					}
					&.vertical-order-3 {
						order: 3;
					}
				}
			}
		}
	}
	
	&.section-photos {
		&#page {
			.mozaique {
				.thumb-block {
					&.profileban {
						width: 33.3%;
					}
				}
			}
			#main #account-content-block {
				#main-picture-upload,
				#main-banner-upload {
					#main-picture-upload_image-display-error,
					#main-banner-upload_image-display-error {
						.icon-f.icf-file-broken {
							font-size: 60px;
						}
						.main-picture-upload_image-error-content,
						.main-banner-upload_image-error-content {
							margin: -35px 0 0 25px;
						}
					}
				}
			}
			
		}
		&.action-gallery_edit {
			#account-content-block {
				.gallery-panel {
					flex-wrap: wrap;
					.col-infos,
					#edit-form {
						max-width: inherit;
						width: ~'calc(100% - 190px)';
						margin-left: 10px;
					}
					.col-pres {
						margin : 10px 0 0 0;
						max-width: inherit;
						width: 100%;
					}
				}
			}
		}
	}
	
	.instructions.main {
		&.with-premium-infos {
			.circle-infos {
				.col-xs-4 {
					width: 33.333%;
				}
			}
		}
	}
	
	&.anonymous {
		#page {
			&.action-email_subscribe {
				.banner-content {
					width: 65%;
					.marketing-content {
						font-size: 20px;
						span {
							line-height: 0.6;
						}
					}
					
					.logo {
						margin-bottom: 15px;
						
						svg {
							&,
							& > image {
								width: 140px;
								height: 35px;
							}
						}
						.icf-ticket-red {
							font-size: 45px;
						}
					}
					.marketing-mentions {
						p {
							font-size: 14px;
							margin-bottom: 15px;
						}
					}
				}
			}
		}
	}
	
	#login-create-verified-msg + #signin-popup-form {
		margin-top: 20px;
	}
}

//max 767px
@media(max-width: @screen-xs-max) {
	#recently-deleted-videos,
	#recently-replacement-videos {
		padding: 0;
	}
	#pending-uploads {
		padding: @account-panel-padding;
	}
	@import "responsive/resp_account";
}

//max 639px
@media (max-width: 639px) {
	.instructions.main.with-premium-infos {
		.circle-infos {
			.stopwatch-info,
			.circle-info {
				font-size: 130px;
			}
		}
	}
	
	#update-email-prefs_prefs_marketing {
		align-items: flex-start;
		
		h3 {
			position: relative;
			top: auto;
			left: auto;
			width: 100%;
			transform: none;
		}
		
		.marketing-mentions,
		.form-group{
			width: 100%;
		}
	}
}

//max 539px
@media (max-width: 539px) {
	.instructions.main.channel {
		.circle-infos {
			.col-xs-4 {
				margin-left: 0;
				width: 50%;
			}
		}
	}
}

//max 479px
@media(max-width: @screen-xxs-max) {
	@import "responsive/mobile_only";
}

//max 400px
@media(max-width: 400px) {
	.instructions.main {
		.circle-infos {
			.col-xs-4 {
				margin-left: 0;
				width: 50%;
				&:last-child:nth-child(even){
					width: 100%;
				}
			}
		}
		&.channel {
			.circle-infos {
				.col-xs-4 {
					&:last-child:nth-child(even) {
						width: 50%;
					}
				}
			}
		}
	}
	&.action-video_edit {
		#main {
			.video-recap {
				.info-hover {
					.info-content {
						min-width: 150px;
						max-width: 48vw;
					}
				}
			}
		}
	}
}

//max 360px
@media(max-width: 360px) {
	&.anonymous {
		padding: 10px;
		
		#page {
			&.action-email_subscribe {
				width: calc(~'100% + 20px');
				margin: -10px;
			}
		}
	}
	&.section-photos {
		&.action-galleries,
		&.action-gallery_edit {
			.create-form-uploads {
				.upload-require {
					font-size: 9px;
				}
			}
		}
	}
	#login-create-verified-msg {
		margin: -20px;
	}
	.language-switcher-row.has-verif-msg {
		top: 0;
		right: -10px;
	}
}

//max 320px
@media(max-width: 320px) {
	&#page.section-photos.action-gallery_edit {
		.album-menu {
			padding-left: 10px;
			padding-right: 10px;
			.btn {
				&.btn-massive-cancel {
					right: 10px;
				}
			}
		}
	}
	
	&.anonymous {
		#page.action-email_subscribe {
			.contact-footer {
				h2 {
					font-size : 20px;
				}
				ul {
					li {
						.icon-f {
							&.icf-twitter,
							&.icf-life-saver {
								font-size: 30px;
								
								&:after {
									width: 60px;
									height: 60px;
								}
							}
							
						}
						
						.text {
							font-size: 12px;
						}
						
						.premium-link {
							svg {
								&,
								& image {
									width: 60px;
									height: 15px;
								}
								
							}
							.icf-ticket-red {
								font-size: 60px;
							}
						}
					}
				}
			}
		}
	}
}

//min 768px
@media (min-width: @screen-sm-min) {
	&#page {
		.mobile_nav(@header-height + @body-out-padding / 2);
		//.page-static-header(@header-height + @body-out-padding * 2);
		
		body & #header {
			margin: 0;
			width: 100%;
			@media @media-ratio-horizontal {
				body.mobile-menu-opened & {
					margin: 0;
					width: 100%;
				}
			}
		}
		#nav {
			body.mobile-menu-opened & {
				&,
				&.no-ctry-version {
					min-height: 0;
					height: auto;
				}
				.account-main-categories {
					overflow: auto;
					max-height: none;
					//max-height: calc(~"100vh - 90px");
					.max-height-100vh(90px);
				}
				&.opened {
					left: 0;
					padding-bottom: 2*@body-out-padding;
					.ordered-label-list ul li.sub-list,
					#language-switcher-list,
					.country-switch-list {
						&.opened {
							border-left: 0;
							box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, .5);
							max-height: none;
							max-height: calc(~"100% - 16px");
							min-height: calc(~"100% - 16px");
							overflow: auto;
							padding-bottom: 16px;
						}
					}
					//transition: all @nav-mobile-opening-duration*2;
				}
			}
		}
		//@media @media-ratio-horizontal {
		//	&,
		//	body.mobile-menu-opened &,
		//	body.header-site-settings-menu-opened & {
		//		margin: 0;
		//	}
		//}
	}
	
	&.anonymous {
		padding-top: 20px;
	}
	
	#main {
		.favlist-edit {
			.form-horizontal {
				.control-label {
					font-weight: bold;
					text-align: right;
				}
			}
		}
	}
	
	.exec-social_connect-finalize_connection {
		#signin-popup-form {
			.form-buttons > div {
				width: 83.333333%;
				margin-left: 16.666667%;
				.btn {
					width: auto;
				}
			}
		}
	}
}

//min 1200px
@media (min-width: @screen-lg-min) {
	#main {
		fieldset.desktop-choice-back-contener {
			.desktop-choice-back {
				font-size: 250px;
				margin-left: -420px;
				&.icf-sexe-man,
				&.icf-sexe-woman {
					font-size: 200px;
				}
				&.icf-sexe-man {
					margin-left: -440px;
					top: 10px;
				}
				&.icf-sexe-woman {
					margin-left: -300px;
					top: -100px;
				}
			}
		}
	}
}

//min 1600px
@media (min-width: @screen-xlg-min) {
	.stopwatch-info,
	.circle-info {
		font-size: 162px;
	}
}

//min 1801px
@media (min-width: 1801px) {
	#main {
		#content {
			#account-tab-nav {
				li {
					&.with_hover_sub_menu:hover {
						& > a.active {
							background: @body-bg;
						}
						.nav-tab-sub-menu {
							&.active {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
