@assets-path: 'skins/default/red/premium-landings/camp2';
@font-path: '../../../../v-00000000001/v3/fonts/@{assets-path}/';

.bg_img_folder(@file) {
	background-image: url("../../../../v-00000000001/v3/img/@{assets-path}/@{file}");
}

& {
	max-width: 1440px;
	margin: -20px auto;
	padding: 0;
	overflow: hidden;
	
	.arrow {
		position: absolute;
		left: 50%;
		bottom: 30px;
		display: block;
		width: 19px;
		height: 17px;
		.bg_img_folder('arrow.png');
		background-size: cover;
		transform: translateX(-50%);
	}
	
	.banner {
		position: relative;
		background-position: center bottom;
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
		
		&:before {
			content: '';
			display: block;
			padding-top: 56.24%;
		}
		
	}
	
	.banner-top {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: #000000;
		z-index: 1;
		
		.menu-container {
			text-align: center;
			line-height: 1;
			.menu-logo {
				margin: 10px 0;
			}
		}
	}
	
	.banner-content {
		position: absolute;
		top: 50%;
		right: 5%;
		width: 50%;
		text-align: center;
		transform: translateY(-50%);
		
		h1 {
			margin: 0;
		}
		
		.marketing-content {
			text-transform: uppercase;
			font-size: 42px;
			font-weight: normal;
			span {
				display: block;
				font-size: 5em;
				font-weight: bold;
				line-height: 0.8;
			}
		}
		.logo {
			margin-bottom: 50px;
			
			.icf-ticket-red {
				font-size: 82px;
				color: @red-color;
			}
		}
		.marketing-mentions {
			p {
				display: inline-block;
				width: 70%;
				font-size: 24px;
				margin-bottom: 30px;
				
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	
	.content-page {
		position: relative;
		padding: 98px 0;
		background: #000000;
		background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(33,33,33,1) 100%);
	}
	
	#optin-form {
		max-width: 675px;
		margin: 0 auto;
		
		.form-group {
			margin: 0;
			padding: 15px 30px;
			
			label {
				font-size: 18px;
			}
			
			&.form-field-email-subscribe-form_name,
			&.form-field-email-subscribe-form_email,
			&.form-field-email-subscribe-form_typexxx {
				background: #161616;
				
				label {
					line-height: 1;
				}
			}
			
			&.form-field-email-subscribe-form_name {
				padding-top: 30px;
			}
			
			&.form-field-email-subscribe-form_typexxx {
				margin-bottom: 40px;
				padding-bottom: 30px;
			}
			
			&.form-field-email-subscribe-form_marketing_internal,
			&.form-field-email-subscribe-form_marketing_partners {
				padding : 0 0 20px 0;
				
				.content {
					text-align: left;
					padding-left: calc(~'16.66666667% + 30px');
					
					.form-horizontal .checkbox {
						padding-top: 0;
					}
					
					.checkbox.toggle {
						label {
							&:before {
								background: #282828;
								border-color: #282828;
							}
						}
						input:checked {
							& ~ label {
								&:before {
									background: @red-color;
								}
							}
						}
					}
				}
			}
			
			&.form-buttons {
				.col-sm-offset-2 {
					margin-left: 0;
					
					&.col-sm-10 {
						float: none;
						width: 100%;
					}
				}
				.btn-danger {
					padding: 20px 90px;
					font-size: 18px;
					border-radius: 20px;
				}
			}
		}
	}
	
	.contact-footer {
		margin-bottom: 30px;
		
		h2 {
			 margin: 0;
			 padding: 30px 0;
			 text-align: center;
			 text-transform: uppercase;
			 font-weight: normal;
			 
			 strong {
				 display: block;
				 font-weight: bold;
				 color: @red-color;
			 }
		 }
		
		ul {
			display: flex;
			align-items: stretch;
			justify-content: center;
			padding: 30px 0 25px 0;
			margin: 0;
			list-style: none;
			background: #292929;
			
			li {
				margin: 0;
				text-align: center;
				& ~ li {
					margin-left: 78px;
				}
				
				a {
					position: relative;
					display: block;
					padding-bottom: 35px;
					text-decoration: none;
					
					&.premium-link {
						width: 95px;
					}
					
					&:hover {
						.icon-f {
							&.icf-twitter,
							&.icf-life-saver {
								&:after {
									background: darken(@red-color, 10%);
								}
							}
						}
					}
				}
				
				svg {
					display: block;
					margin: 0 auto;
				}
				
				.icon-f {
					display: inline-block;
					
					&.icf-twitter,
					&.icf-life-saver {
						position: relative;
						font-size: 40px;
						
						&:before {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate3d(-50%, -50%, 0);
						}
						
						&:after {
							content: '';
							display: block;
							width: 74px;
							height: 74px;
							border-radius: 50%;
							background: @red-color;
							transition : background 0.2s;
						}
					}
					
					&.icf-ticket-red {
						font-size : 76px;
						line-height: 0.7;
						color: @red-color;
					}
				}
				
				.text {
					position: absolute;
					left: 0;
					bottom: 0;
					display: block;
					width: 100%;
					margin: 0;
					text-align: center;
					text-transform: uppercase;
					font-weight: bold;
				}
				
			}
		}
	}
}
&.straight {
	.banner {
		.bg_img_folder('bg-straight.png');
	}
}
&.gay {
	.banner {
		.bg_img_folder('bg-gay.png');
	}
}
&.trans,
&.shemale {
	.banner {
		.bg_img_folder('bg-trans.png');
	}
}

html[lang="pt"] & {
	.banner-content {
		.marketing-content {
			font-size: 35px;
			span {
				font-size: 3em;
			}
		}
	}
}