
&.exec-channels-membership_program {
	.alert-warning {
		margin: 5px 0;
	}
	h5 {
		margin: 15px 0;
		line-height: 32px;
		font-size: 18px;
		span {
			font-weight: normal;
		}
		+ p {
			margin: -15px 0 15px;
		}
	}
	.form-horizontal {
		margin-top: 20px;
	}
	#cancel-membership_warning {
		legend,
		.alert {
			margin-bottom: 10px;
		}
	}
}

&.exec-uploads-video_upload,
&.exec-channels-request_status_model,
&.exec-channels-request_status_channel {
	.sheer {
		background: @account-panel-border;
		border: 3px solid @account-video-status-danger-color;
		border-radius: 5px;
		padding: 20px;
		p {
			font-size: 16px;
			&.sheer-link {
				font-size: 24px;
				margin: 20px 0;
				.sheer-tag {
					height: 36px;
					line-height: 38px;
				}
			}
		}
	}
}