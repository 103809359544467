
&.exec-manage-channel_memberships #account-content-block,
&.exec-manage-premium #account-content-block {
	.panel-body {
		.status-frame {
		
		}
		h1.status {
			margin: 40px 0;
			padding: 30px;
			border: 1px solid @account-block-border;
			background: @account-block-bg;
			border-radius: @account-block-border-radius;
			text-align: center;
			line-height: 1.3;
			small {
				color: @account-block-small-color;
				display: block;
				margin-top: 10px;
				line-height: 1.3;
			}
			
			.premium-resub {
				font-size: 20px;
				white-space: normal;
			}
		}
		.btn-container {
			text-align: center;
			.btn-lg {
				font-size: 24px;
			}
		}
		table {
			margin-top: 40px;
		}
		@media(max-width: @screen-sm) {
			h1 {
				font-size: 20px;
				&.status {
					margin: 20px 0;
					padding: 10px;
					
					.premium-resub {
						font-size: 18px;
					}
				}
			}
			.btn-container .btn-lg {
				font-size: 16px;
				white-space: normal;
			}
			table {
				margin-top: 20px;
			}
		}
		#card-change-form-block {
			padding: 0 20px 10px;
			margin-bottom: 30px;
			border: 1px solid @account-block-border;
			background: @account-block-bg;
			border-radius: @account-block-border-radius;
		}
		#premium-form {
			.form-group {
				.cc-icon {
					top: 3px;
					right: 3px;
				}
				&.form-buttons {
					margin-left: -15px;
					margin-right: -15px;
				}
			}
		}
	}
}