
@import "main";
@import "../default/responsive/resp_nav.less";
@import "../account/theme";

@media (max-width: @screen-all-mobile) {
	.account-page {
		#main {
			#content #account-tab-nav {
				display: none;
			}
		}
	}
}
