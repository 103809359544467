
#totp-apps-table {
	.form-group {
		margin-bottom: 0;
	}
	.totp-title-edit {
		vertical-align: middle;
		margin-left: 5px;
		cursor: pointer;
	}
}

&.exec-manage-close {
	#reason-close_confirm_centered {
		h5 {
			margin-bottom: 1em;
		}
		.btn-group {
			margin-left: 0;
			.radio label {
				font-weight: bold;
			}
		}
	}
	
	#navigation-history {
		.btn {
			.init-ok-btn();
		}
	}
}

&.exec-manage-send_documents {
	#send-documents_attachments legend {
		margin-bottom: 5px;
	}
	
	#documents-files-restriction-header {
		margin-bottom: 18px;
		font-style: italic;
	}
}

&.exec-manage-profile_edit #edit-profile_consent,
&.exec-manage-profile_privacy_settings #account-privacy-settings_consent {
	padding: 15px;
	margin-bottom: 15px;
	border: 1px solid @red-color;
	background: @gray-lighter;
}

.exec-social_connect-finalize_connection {
	#signin-popup-form {
		.form-buttons > div {
			.btn {
				width: 100%;
				
			}
		}
	}
	#social-create-form {
		margin-top: 20px;
	}
}
