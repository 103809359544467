&.exec-manage-verify_profile {
	#main #content #rejected-verify-request .table tr td.pic-col {
		text-align: center;
		padding-top: 1px;
		padding-bottom: 1px;
	}
	#rejected-verify-request {
		overflow: auto;
		max-height: 300px;
		.pic-col {
			a {
				display: inline-block;
			}
			img {
				max-width: 200px;
				max-height: 100px;
			}
		}
		.reject-reason {
			ul li {
				font-size: 120%;
			}
		}
	}
}