&.section-photos {
	&#page {
		.col-img-big {
			padding: 0 15px;
			max-width: 30%;
			width: 307px + 30px;
		}
		
		.col-minus-img-big {
			min-width: 70%;
			width: calc(~"100% - 307px - 30px");
		}
		
		.mozaique {
			.thumb-block {
				overflow: visible;
				
				.is-main {
					display: none;
				}
				
				.thumb-inside {
					overflow: visible;
					
					img {
						border: 1px solid @thumb-block-thumb-border;
					}
					
					.btn-upload {
						width: 100%;
						height: 100%;
					}
				}
				
				.pic-request-actions {
					margin-top: -2px;
					width: 100%;
					
					.btn {
						border-color: @thumb-block-thumb-border;
						border-width: 1px;
						cursor: default;
						display: block;
						margin: 0;
						opacity: 0.5;
						padding: 4px 0;
						white-space: normal;
						line-height: 14px;
						width: 100%;
						word-break: break-word;
						
						&.init-ok {
							cursor: pointer;
							opacity: 1;
						}
						
						&.reset-ps-prof-pic,
						&.setasmain,
						&.delete {
							border-top: 0;
							border-radius: 3px;
							border-top-left-radius: 0;
							border-top-right-radius: 0;
							float: left;
							width: 50%;
						}
						
						&.reset-ps-prof-pic {
							border-right-width: 0;
							border-bottom-right-radius: 0;
						}
						
						&.setasmain {
							border-right-width: 0;
							border-bottom-right-radius: 0;
							
							.icon-f {
								color: @red-color;
							}
						}
						
						&.delete {
							border-bottom-left-radius: 0;
							
							.icon-f {
								opacity: 0.6;
							}
							
							&:hover {
								.icon-f {
									opacity: 1;
								}
							}
						}
						
						.icon-f {
							font-size: 14px;
							vertical-align: text-bottom;
						}
					}
					
					&.no-del {
						.btn {
							&.reset-ps-prof-pic,
							&.setasmain {
								border-bottom-right-radius: 3px;
								border-right-width: 1px;
								width: 100%;
							}
						}
					}
				}
				
				.is-under-review,
				&.is-main .is-main {
					border-color: transparent @red-color transparent transparent;
					border-style: solid;
					border-width: 0 30px 30px 0;
					display: block;
					height: 0;
					position: absolute;
					right: -1px;
					top: -1px;
					width: 0;
					
					.icon-f {
						color: #fff;
						display: block;
						height: 20px;
						line-height: 20px;
						position: relative;
						text-align: right;
						width: 26px;
						z-index: 3;
					}
					
					&:after,
					&:before {
						border-color: transparent @red-color transparent transparent;
						border-style: solid;
						border-width: 0 30px 30px 0;
						content: " ";
						height: 0;
						left: 0;
						position: absolute;
						top: 0;
						width: 0;
						z-index: 2;
					}
					
					&:before {
						border-color: transparent rgba(0, 0, 0, .5) transparent transparent;
						border-width: 0 29px 29px 0;
						filter: blur(2px);
						left: -2px;
						top: 3px;
						z-index: 1;
					}
				}
				.is-under-review {
					border-width: 0 38px 38px 0;
					.icon-f {
						width: 34px;
					}
					&:after {
						border-width: 0 38px 38px 0;
					}
					&:before {
						border-width: 0 37px 37px 0;
					}
				}
				
				&.is-under-review,
				&.is-main {
					.pic-request-actions {
						.btn {
							&.reset-ps-prof-pic {
								display: inline-block;
							}
							
							&.setasmain {
								display: none;
							}
							
							&.delete {
								border-bottom-left-radius: 3px;
								width: 100%
							}
						}
					}
				}
				
				&.profilepic {
					.thumb-inside {
						padding-bottom: 100%;
					}
					
					&.profilepic169 {
						.thumb-inside {
							padding-bottom: 56.7%;
						}
					}
				}
				
				&.profileban {
					width: 25%;
					
					.thumb-inside {
						padding-bottom: 16%;
					}
				}
			}
		}
		
		#main #account-content-block {
			.banner-size-info {
				margin-bottom: 1em;
				text-align: center;
				line-height: 1;
				
				.icon-f {
					display: inline-block;
					vertical-align: top;
				}
			}
			
			#main_profileban,
			#main_profilpic {
				border: 1px solid @thumb-block-thumb-border;
				display: block;
				margin: 0 auto 15px;
				max-width: 305px;
				width: 100%;
			}
			
			#main_profileban {
				max-width: 1280px;
			}
			
			.panel-upload {
				margin-bottom: 5px;
				text-align: center;
			}
			
			.btn-upload {
				margin: 0;
				opacity: 0.5;
				cursor: default;
				
				&.is-init {
					opacity: 1;
					cursor: pointer;
				}
			}
			
			#main-picture-upload,
			#main-banner-upload {
				height: 0;
				text-align: center;
				overflow: hidden;
				
				&.is-init {
					height: auto;
					overflow: initial;
				}
				
				.form-field-main-picture-upload_image {
					margin-bottom: 0;
				}
				
				label {
					position: relative;
					max-width: 300px;
					margin: 0 auto 15px;
					z-index: 1;
					padding-bottom: 10px;
					
					&.hl-focus {
						animation: input_error_focus 1.5s ease-out;
					}
				}
				
				input[type="file"] {
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 0;
					z-index: 0;
				}
				
				#main-picture-upload_image-display-error,
				#main-banner-upload_image-display-error {
					position: relative;
					max-width: 300px;
					height: auto;
					margin: 0 auto 15px;
					text-align: center;
					background: #e6e6e6;
					border: 2px solid rgba(0, 0, 0, 0.5);
					
					&:before {
						content: '';
						display: block;
						padding-top: 100%;
					}
					
					.icon-f.icf-file-broken {
						position: absolute;
						left: 0;
						top: 50%;
						display: inline-block;
						width: 100%;
						font-size: 120px;
						color: rgba(0, 0, 0, 0.4);
						transform: translateY(-50%);
					}
					
					.main-picture-upload_image-error-content,
					.main-banner-upload_image-error-content {
						position: absolute;
						top: 50%;
						left: 50%;
						margin: -66px 0 0 40px;
					}
				}
				
				#main-picture-upload_image-display,
				#main-banner-upload_image-display {
					position: relative;
					font-size: 10px;
					
					.cr-boundary:before {
						content: @icf-spinner;
						position: absolute;
						top: 50%;
						left: 50%;
						display: block;
						width: 1em;
						height: 1em;
						margin: -0.5em 0 0 -0.5em;
						font-family: '@{icomoon-font-family}';
						font-size: 5em;
						line-height: 1em;
						color: #a8a8a8;
						z-index: -2;
						animation: icf_spin_anim 1.25s linear infinite;
					}
				}
				
				#main-picture-upload_image-actions,
				#main-banner-upload_image-actions {
					position: absolute;
					top: calc(100% - 46px);
					left: 0;
					width: 100%;
					height: 30px;
					margin-top: -30px;
					text-align: center;
					font-size: 0;
					line-height: 30px;
					background: rgba(0, 0, 0, 0.5);
					z-index: 2;
					
					.btn {
						width: 50%;
						margin: 0;
						padding: 0;
						font-size: 22px;
						line-height: 1;
						color: white;
						background: none;
						border: 0;
						transition: transform 0.3s;
					}
				}
				
				.form-buttons {
					.col-sm-9.col-sm-offset-3 {
						margin-left: 0;
						text-align: center;
						width: 100%;
					}
				}
			}
			
			#main-picture-upload {
				#main-picture-upload_image-display {
					max-width: 300px;
					margin: 0 auto;
				}
			}
			
			#main-banner-upload {
				text-align: center;
				margin: 0 auto;
				max-width: 1280px;
				
				#main-banner-upload_image-display-error {
					max-width: 1280px;
					
					&:before {
						content: '';
						display: block;
						padding-top: 15.625%;
					}
				}
			}
			
			.croppie-container {
				width: auto;
				height: auto;
				
				.cr-viewport {
					box-shadow: none;
					border-color: fade(@text-color, 30%);
				}
				
				.cr-slider-wrap {
					max-width: 300px;
					margin: 0 auto;
					padding: 15px 0;
					
					.cr-slider {
						width: 100%;
						height: 6px;
						padding: 8px 0;
						margin: 0;
						&::-webkit-slider-thumb {
							margin: -13px 0 0 0;
						}
					}
				}
			}
		}
		
		#picture-tabs {
			> .nav-tabs a {
				color: @gray;
				
				&.active {
					color: @text-color;
				}
			}
			> .tabs > .tab {
				display: none;
				
				&.active {
					display: block;
				}
			}
		}
	}
}
