.voters-list{
	max-width: 608px;
	overflow: hidden;
	.thumb-profile{
		float: left;
		overflow: hidden;
		text-align: center;
		width: 76px;
		.thumb{
			background: none;
			display: block;
			height: 62px;
			position: relative;
			width:  auto;
			img{
				border: 1px solid @text-color;
			}
			.icon-f{
				text-shadow: 1px 1px 0 #000, 0 0 1px #000;
				bottom: 4px;
				color: #fff;
				position: absolute;
				right: 10px;
				text-decoration: none;
			}
		}
		.profile-name{
			font-weight: normal;
			font-size: 14px;
			height: 18px;
			line-height: 18px;
			margin: 0 0 6px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	& > p {
		clear: both;
		padding: 10px 0 0;
		text-align: center;
		.btn{
			color: @text-color;
		}
	}
}
.voters-list-pop{
	margin: -@body-out-padding;
	padding: @body-out-padding 20px;
	width: calc( ~"100% + " 2*@body-out-padding );
	.x-body{
		max-width: 658px;
	}
	h2{
		margin-bottom: 20px;
	}
}