
@import "modules/page";

#main {
	padding: 0 4px;
	> :first-child {
		margin-top: 5px;
	}
	#is-sheer-creator-chat-message {
		.panel#account-content-block {
			display: none;
		}
	}
	.panel-body {
		.sheer {
			background: @account-panel-border;
			border: 3px solid @account-video-status-danger-color;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			border-radius: 5px;
			padding: 20px;

			p {
				font-size: 16px;
			}

			.sheer-tag {
				height: 36px;
				line-height: 38px;
			}

			.sheer-link {
				font-size: 24px;
				margin: 20px 0;
			}
		}
	}
	#account-content-block {
		& > .panel-heading {
			h2 {
				font-size: 18px;
				font-weight: normal;
				line-height: 20px;
				margin: 0;
				
				.icon-f {
					display: inline-block;
					line-height: 20px;
					vertical-align: top;
				}
			}
		}
		
		& > .panel-body {
			border: 1px solid @account-panel-border;
			padding: @account-panel-padding;
		}
	}
}

.info-bordered {
	border: 1px solid @text-color;
	font-size: 15px;
	line-height: 22px;
	padding: 10px;
	ul {
		margin: 10px 0 10px;
	}
}

@import "modules/account";
@import "modules/channels";
@import "modules/dashboard";
@import "modules/manage";
@import "modules/verify_profile";
@import "modules/premium";
@import "modules/video_edit";
@import "modules/video_delete";
@import "modules/video_list";
@import "modules/video_statistics";
@import "modules/video_upload";
@import "modules/social_friends";
@import "modules/social_network_signin";
@import "modules/my_photos";
@import "modules/my_galleries";
@import "modules/support";
@import "modules/promo_links";
@import "modules/review_list";
//@import "modules/notifications";
