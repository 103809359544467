&.section-support,
&.exec-uploads-video_notice_discussion {
	.x-thread {
		.thread-node {
			border-top: 1px solid @gray-bg;
			padding-top: 8px;
			.thread-node-poster {
				border-bottom: 1px solid @gray-lighter;
			}
			.thread-node-metadata {
				padding-top: 10px;
			}
		}
	}
	#conversation_attachments legend {
		margin-bottom: 5px;
	}
	#support-files-restriction-header {
		margin-bottom: 18px;
		font-style: italic;
	}
}

&.anonymous .section-support {
	#signin-popup-form {
		.x-thread {
			.text-disabled,
			.thread-node .thread-node-content .thread-node-poster .thread-node-date {
				color: #eee;
			}
			.thread-header .thread-node-children-count {
				color: black;
				background: white;
			}
		}
	}
}

&.exec-uploads-video_notice {
	.counter-notice_legal_statement_content_description,
	.counter-notice_coordinates_description {
		font-size: 14px;
		margin-bottom: 20px;
	}
}

.exec-support-country_testers {
	#signin-popup-form {
		h5,
		form {
			margin-left: 0;
			margin-right: 0;
			padding-left: 5px;
			padding-right: 5px;
		}
		.radio label {
			font-size: 16px;
		}
	}
}

#discussion {
	.events {
		&.internal-error {
			color: @red-color;
			font-size: 24px;
			margin: 10px 0;
			text-align: center;
		}
		fieldset {
			legend {
				font-size: 17px;
				cursor: pointer;
				&:before {
					.font-family-iconfont();
					content: @icf-angle-down;
					margin-right: 6px;
				}
				&.is-open:before {
					content: @icf-angle-up;
				}
				.date {
					float: right;
					font-style: italic;
					font-size: 14px;
				}
				.unread, .waiting-support {
					display: inline-block;
					margin-left: 10px;
				}
			}
			&.event-strike,
			&.event-infraction {
				margin-bottom: 10px;
				legend {
					border-bottom: 1px solid #e5e5e5;
					margin-bottom: 10px;
					.icon-f:not(.icon-canceled) {
						color: @red-color;
					}
					a {
						color: #ccc;
					}
					.canceled {
						text-transform: uppercase;
						font-size: 9px;
					}
				}
				.strike-content,
				.infraction-content {
					background: @account-discussion-admin-bg;
					border-left: 2px solid @account-video-status-danger-color;
					border-radius: 5px;
					min-height: 45px;
					padding: 8px;
					a.scroll-to,
					a.discussion-popup-create-message {
						background: @account-video-details-color;
						&:hover {
							background: darken(@account-video-details-color, 10%);
						}
						.icon-f {
							font-size: 18px;
							vertical-align: bottom;
						}
					}
				}
			}
			.strike-content,
			.infraction-content {
				ul {
					font-size: 12px;
					margin:0;
					padding-left: 10px;
				}
				a.toggle-details {
					font-size: 12px;
				}
				.detailed-reasons {
					display: none;
				}
			}
			.wrapper-messages {
				.actions {
					text-align: right;
					a {
						display: inline-block;
						margin-bottom: 5px;
						margin-right: 15px;
					}
					.wrapper-assign-to {
						display: inline-block;
						.btn.assignment-updated {
							cursor: default;
							margin-right: 15px;
						}
					}
				}
				.row {
					margin-left: 0;
					margin-right: 0;
				}
				.reviewed-content {
					background: @account-discussion-reviewed-content-bg;
					border-radius: 5px;
					margin-bottom: 20px;
					padding: 8px;
					ul {
						margin: 0 0 0 20px;
						padding: 0;
					}
				}
				.messages {
					.message {
						margin-bottom: 10px;
						.message-content {
							border-radius: 5px;
							padding: 8px;
							&.from-support {
								background: @account-discussion-admin-bg;
							}
							&.from-you {
								background: @account-discussion-user-bg;
							}
							&.from-requester-uploader {
								background: @account-discussion-requester-uploader-bg;
							}
							.counter-notice {
								h6 {
									border-left: 2px solid @red-color;
									border-radius: 2px;
									display: inline-block;
									margin-bottom: 20px;
									padding: 4px;
								}
								ul {
									margin: 0.5em 0;
									padding-left: 2em;
								}
							}
							.event-metadata {
								margin-top: 10px;
							}
						}
						.datas {
							font-size: 12px;
							font-style: italic;
							margin-bottom: 3px;
						}
					}
					div.sub-topic {
						border-left: 3px solid @form-horizontal-radio-inp-inact-border;
						margin: 10px 0;
						padding: 10px;
					}
				}
			}
		}
		.no-more-messages {
			margin: 20px 0;
			text-align: center;
		}
	}
	.manager-unavailable {
		background: @account-panel-border;
		border: 3px solid @account-video-status-danger-color;
		border-radius: 5px;
		margin: 20px 0;
		padding: 10px;
	}
	.x-loader-inline {
		display: block;
		margin: 20px 0;
		text-align: center;
	}
}

&.anonymous #discussion .events fieldset legend {
	color: #fff;
}

.x-overlay.x-overlay-box.discussion-form {
	.x-body {
		max-width: 1200px;
		fieldset#conversation_main {
			.conversation_main_description {
				color: @red-color;
				font-weight: bold;
				margin-bottom: 20px;
			}
		}
		.conversation_attachments_description {
			font-size: 12px;
			font-style: italic;
		}
		input:disabled {
			background: none;
			border: none;
			color: #fff;
			padding-left: 0;
		}
	}
}