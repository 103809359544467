#main {
	.form-horizontal {
		&.form-step {
			fieldset {
				&.centered {
					.form-group {
						.btn-group,
						.checkbox-list {
							.btn {
								width: 100%;
								
								label {
									font-size: 14px;
									height: 40px;
									line-height: 40px;
									padding: 0 15px 0 24px;
									width: 100%;
									
									input {
										left: 10px;
									}
								}
							}
						}
						
						.btn-group {
							.btn {
								&:first-child {
									&, label {
										border-bottom-left-radius: 0;
										border-top-right-radius: 8px;
									}
								}
								
								&:last-child {
									&, label {
										border-bottom-left-radius: 8px;
										border-top-right-radius: 0;
									}
								}
								
								& + .btn {
									margin-left: 0;
									margin-top: -1px;
								}
							}
						}
						
						.btn-group.btn-disjoint,
						.checkbox-list {
							.btn {
								margin-left: 0;
								margin-right: 0;
								
								&,
								&:first-child {
									&,
									label {
										border-radius: 8px;
									}
								}
								
								&.ticket-red {
									border-radius: 0;
									
									label {
										border-radius: 0;
										
										input {
											left: 16px;
										}
										
										.icf-check-circle {
											left: 13px;
											top: 12px;
										}
									}
									
									.ticket-in {
										font-size: 14px;
									}
									
									/*.ticket-in {
										height: 40px;
										line-height: 40px;
									}
									.ticket-back {
										.ticket-back-left,
										.ticket-back-right,
										.icon-f{
											font-size: 41px;
										}
									}*/
								}
							}
							
							.ticket-red-height(40px);
						}
					}
					
				}
				
				#premium-instruction-block,
				#content-prog-instruction-block,
				#regional-event-instruction-block {
					margin: 0 10px;
					
					ul {
						padding-left: 20px;
					}
				}
			}
			
			& > fieldset {
				padding-left: 15px;
				padding-right: 15px;
				
				legend {
					margin-left: 0;
					margin-right: 0;
					width: 100%;
				}
			}
		}
	}
	
	#revshare_form_contener {
		.form-horizontal > fieldset {
			legend {
				margin-left: -15px;
				margin-right: -15px;
				width: calc(~"100% + 30px");
			}
		}
	}
	
	#friendsrequests-actions {
		& > div {
			margin-bottom: 10px;
		}
		
		#friendsrequests-accept,
		#friendsrequests-refuse {
			float: none;
			width: 100%;
			
			.label {
				float: left;
				line-height: 32px;
				width: 40%;
			}
			
			.btn-group {
				width: 60%;
				
				.btn {
					display: block;
					float: none;
					margin: -1px 0 0;
					width: 100%;
					
					&:first-child {
						border-bottom-left-radius: 0;
						border-top-right-radius: 2px;
						margin-top: 0;
					}
					
					&:last-child {
						border-bottom-left-radius: 2px;
						border-top-right-radius: 0;
					}
				}
			}
		}
	}
	
	#account-notifications-actions {
		#account-notifications-settings {
			.icon-f {
				margin: 0;
			}
		}
		
		.mobile-only-inner-only {
			font-size: 0;
			
			& > * {
				font-size: 12px;
				text-transform: capitalize;
			}
		}
	}
}


#comments-list {
	.video {
		.video-thumb,
		.video-details {
			width: 100%;
		}
		
		.video-thumb {
			img {
				float: left;
				width: 30%;
			}
			
			.video-details {
				float: left;
				padding-left: 8px;
				padding-right: 8px;
				text-align: left;
				width: 70%;
			}
		}
	}
}

#profile-walls-list {
	.thumb-block {
		width: 15%;
		margin-right: 5px;
		margin-top: 5px;
		
		.profile-counts {
			display: none;
		}
	}
	
	.wall-comments {
		overflow: hidden;
	}
	
	.thread-node-children-nodes {
		.thread-node {
			.thread-node-metadata {
				
				.icf-thumb-up,
				.icf-thumb-down {
					padding: 10px 5px;
				}
				
				.thread-node-btn-delete,
				.thread-node-btn-edit,
				.thread-node-btn-post,
				.thread-node-btn-report,
				.thread-node-btn-replies {
					padding-left: 10px;
				}
			}
		}
	}
}

.nav-tabs {
	& > li {
		& > a {
			.icon-f {
				font-size: 20px;
			}
			
			.txt {
				display: none;
			}
		}
	}
}

.video-list {
	.video {
		.video-details {
			.video-nb-views {
				&.long {
					display: none;
				}
				
				&.short {
					display: inline;
				}
			}
		}
	}
}

&.action-video_edit {
	#main {
		.video-recap {
			.video-details {
				.video-nb-views {
					&.long {
						display: none;
					}
					
					&.short {
						display: inline;
					}
				}
			}
		}
	}
}

#recently-replacement-videos,
#recently-deleted-videos,
#pending-uploads {
	p.block-title {
		font-size: 14px;
	}
}

#main-big-stats {
	.row > div {
		width: 100%;
		
		.frame {
			font-size: 14px;
			margin: 0 4px 4px;
			
			strong {
				display: inline;
			}
		}
	}
}

.instructions.main {
	&,
	&.with-premium-infos {
		.circle-infos {
			.stopwatch-info,
			.circle-info {
				font-size: 110px;
			}
		}
	}
}

#uploader-stats-filter {
	.form-field-uploader-stats-filter_dates_from,
	.form-field-uploader-stats-filter_dates_to {
		width: auto;
		float: none;
		margin-bottom: 2px;
	}
	
	.form-field-uploader-stats-filter_dates_from {
		.input-group .input-group-addon:nth-child(3) {
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
		
		.input-group .input-group-addon:last-child {
			display: none;
		}
	}
	
	.form-field-uploader-stats-filter_dates_to {
		.input-group-addon:first-child {
			display: table-cell;
		}
	}
	
	.form-field-uploader-stats-filter_accounts {
		.checkbox {
			display: block;
		}
	}
}

#main-stats-chart {
	#stats-views-chart {
		height: 700px;
	}
}

&.section-uploads {
	#profile-vids-release-rates {
		fieldset {
			white-space: normal;
			width: calc(~"100% - 34px - @{account-panel-padding}");
		}
		
		p,
		select {
			margin: 0;
			width: 100%;
		}
	}
	#video-tab-customize .title-filter-container {
		clear: left;
	}
}


&.section-photos {
	&#page {
		.col-img-big {
			border-bottom: 1px solid @gray-lighter;
			margin: 0 15px 20px;
			max-width: calc(~"100% - 30px");
			min-width: calc(~"100% - 30px");
			width: calc(~"100% - 30px");
			padding: 0 0 10px;
			
			img {
				max-width: 240px;
			}
		}
		
		.col-minus-img-big {
			width: 100%;
		}
		
		#main-picture-upload {
			text-align: center;
		}
		
		.album-menu {
			padding: 20px;
			z-index: 300;
			
			.album-menu-title {
				margin-right: 7px;
				font-size: 18px;
				
				.icon-f {
					margin-right: 5px;
				}
			}
			
			.btn {
				
				&.btn-move-first {
					padding: 6px;
					margin-right: 5px;
				}
				
				&.btn-massive-delete {
					font-size: 0;
					
					span {
						font-size: 13px;
					}
				}
				
				&.btn-massive-cancel {
					right: 20px;
				}
			}
		}
		
		.mozaique {
			.thumb-block {
				&.profileban {
					width: 100%;
				}
			}
		}
		
	}
}

#update-email-prefs_prefs_marketing {
	h3 {
		margin-bottom: 15px;
		
		div {
			.marketing-content {
				font-size: 20px;
				
				span {
					font-size: 100px;
				}
			}
			
			.logo {
				svg {
					margin-bottom: 0;
					
					&,
					& > image {
						width: 140px;
						height: 35px;
					}
				}
				
				.icf-ticket-red {
					font-size: 42px;
				}
			}
		}
	}
}

html[lang="fr"] &,
html[lang="pt"] & {
	#update-email-prefs_prefs_marketing {
		h3 {
			.marketing-content {
				font-size: 17px;
			}
		}
	}
}

html[lang="fr"] & {
	#update-email-prefs_prefs_marketing {
		h3 {
			.marketing-content {
				span {
					font-size: 37px;
				}
			}
		}
	}
}

html[lang="pt"] & {
	#update-email-prefs_prefs_marketing {
		h3 {
			.marketing-content {
				span {
					font-size: 50px;
				}
			}
		}
	}
}
