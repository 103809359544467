
#dashboard {
	.tabs {
		.tab {
			& + .tab {
				display: none;
			}
		}
	}
	.panel {
		background: @dashboard-panel-bg;
		margin-bottom: @account-panel-padding*2;
		.panel-heading {
			.toggle {
				background: @dashboard-panel-bg;
				bottom: 0;
				color: @text-color;
				font-size: 21px;
				line-height: @dashboard-panel-link-padding-v * 2 + @dashboard-panel-link-line-height;
				opacity: 0.5;
				position: absolute;
				right: 0;
				top: 0;
				text-align: center;
				text-decoration: none;
				transition: all 0.2s ease;
				width: 40px;
				&:hover {
					opacity: 1;
					/*box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) inset;
					.icon-f {
						transform: translateY(2px);
					}*/
				}
				.icon-f {
					display: inline-block;
					font-weight: bold;
					transition: all 0.15s ease;
				}
				.icf-angle-up {
					display: none;
				}
			}
		}
		.panel-body {
			background: @dashboard-panel-bg;
			max-height: 0;
			opacity: 0;
			padding: 0 @account-panel-padding 2px;
			h2 {
				font-size: 20px;
			}
			h3 {
				font-size: 18px;
			}
			h4 {
				font-size: 16px;
			}
			h5 {
				font-size: 15px;
			}
			h2,
			h3,
			h4,
			h5 {
				margin: 1em 0.25em 0.5em;
				&:first-child {
					margin-top: 0.25em;
				}
			}
		}
		&.opened {
			.panel-heading {
				.toggle {
					.icf-angle-down {
						display: none;
					}
					.icf-angle-up {
						display: inline-block;
					}
				}
			}
		}
		&.opened {
			.panel-body {
				border-width: 1px;
				max-height: 2000px;
				opacity: 1;
				padding-bottom: @account-panel-padding;
				padding-top: @account-panel-padding;
			}
		}
		&.news {
			position: relative;
			.panel-body {
				overflow: auto;
				max-height: 0;
			}
			&.opened {
				.panel-body {
					max-height: 400px;
				}
			}
			.onenews {
				background: @dashboard-panel-link-bg;
				border: 1px solid @gray-lighter;
				color: @text-color;
				cursor: pointer;
				margin-bottom: @account-panel-padding;
				padding: @account-panel-padding;
				transition: height 0.5s ease-in-out, all 0.2s ease;
				word-break: break-word;
				.n-date {
					border-bottom: 1px solid @gray-lighterer;
					color: @text-color;
					font-size: 13px;
					font-weight: 700;
					margin-bottom: 5px;
					padding-bottom: 5px;
					.new {
						color: @red-color;
					}
				}
				.n-title {
					font-size: 19px;
					font-weight: normal;
					line-height: 1;
					margin: 5px 0;
				}
			}
		}
		&.red {
			.panel-heading {
				.ticket-red {
					.ticket-in {
						font-size: 11px;
						height: 20px;
						line-height: 20px;
					}
					.ticket-back .ticket-back-left .icon-f,
					.ticket-back .ticket-back-right .icon-f {
						font-size: 20px;
					}
				}
			}
			.panel-body {
				p.stats-warning {
					margin-top: 5px;
					margin-bottom: 0;
					color: @theme-color;
					font-style: italic;
					text-align: center;
				}

				.list-group {
					margin-top: 15px;
				}
			}
		}
		&.red, &.homerating {
			.panel-body {
				font-size: 14px;
				p.stats-title {
					font-weight: bold;
					font-size: 14px;
				}

				p.stats-value {
					a {
						color: @text-color;
					}
				}

				.internal-error {
					.icon-f {
						padding-right: 5px;
					}
				}
			}
		}
		&.social-network-sign-in {
			.panel-heading .icon-f {
				vertical-align: text-bottom;
			}
		}
		&.homerating {
			.panel-body {
				ul.stats-value {
					margin: 0 0 0.5em;
				}
				ul.bonuses, ul.other_bonuses, ul.maluses {
					margin: 0;
					padding: 0;
					li {
						list-style: none;
					}
				}

				ul.bonuses, ul.other_bonuses {
					display: none;
				}

				ul.other_bonuses {
					color: @gray-light;
				}
			}
		}
		&.stats, &.homerating {
			.panel-body {
				background-color: @dashboard-panel-link-bg;
				a,
				.a {
					background-color: @dashboard-panel-bg;
					border: 1px solid @account-panel-border;
					color: @text-color;
					display: block;
					font-size: 15px;
					margin: @account-panel-padding 0;
					text-align: center;
					text-decoration: none;
					transition: all 0.2s;
					.val {
						color: lighten(@text-color, 5%);
						font-size: 23px;
						display: block;
						font-weight: bold;
						line-height: 1;
						padding: 3px 0 0;
					}
				}
				a {
					&:hover {
						background-color: darken(@dashboard-panel-bg, 10%);
					}
				}
			}
		}
		.list-group {
			a.list-group-item,
			.list-group-item a.list-line {
				padding: 10px 16px;
			}
			.list-group-item {
				display: block;
				padding: 0;
				&.pop-icon {
					.icon-f {
						font-size: 16px;
						margin-right: 4px;
					}
				}
				&.with-help {
					.list-line {
						padding-right: 42px;
						.status {
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	.panel-account-status {
		.list-group {
			.list-group-item {
				display: block;
				padding: 0;
				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	.panel-red-promo-link {
		.panel-heading {
			.icf-ticket-red {
				color: @red-color;
				line-height: 18px;
				font-size: 32px;
				vertical-align: text-bottom;
			}
		}
		.list-group-item {
			border: 0;
		}
		.btn {
			border-radius: 0;
			margin-bottom: 0;
		}
	}
}
