&.action-promo_links_select_thumb {
	#select-promo-link-thumb {
		.content {
			width: 100%;
		}
		.thumb-block {
			position: relative;
			overflow: hidden;
			label {
				position: relative;
				width: 100%;
				padding-top: 56.17%;
				font-size: 0;
				border: 1px solid transparent;
				background: @btn-default-bg;
				&:hover {
					border-color: @text-color;
				}
				&[aria-checked="true"] {
					border-color: @btn-danger-border;
					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						width: 100%;
						height: 100%;
						background: rgba(255,0,0,.4);
					}
				}
				&.label-text {
					border-color: @btn-default-border;
					border-radius: @btn-border-radius-base;
					&:hover {
						background: @btn-default-border;
					}
					&[aria-checked="true"] {
						border-color: @btn-danger-border;
						background: @btn-danger-bg;
						&:after {
							display: none;
						}
						span {
							color: @text-invert-color;
						}
					}
				}
				span {
					position: absolute;
					top : 50%;
					left: 0;
					display: block;
					width: 100%;
					padding: 0 20px;
					text-align: center;
					font-size: 15px;
					font-weight: bold;
					line-height: 1;
					color: @btn-default-color;
					transform: translateY(-50%);
					&.icon-f {
						top: auto;
						bottom: 5px;
						left : auto;
						right: 7px;
						width: auto;
						height : auto;
						padding : 0;
						font-size: 24px;
						color: @text-invert-color;
						transform: none;
						z-index: 1;
					}
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					max-width: 100%;
					width: auto;
					height: auto;
				}
			}
			input[type="radio"] {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		.form-buttons {
			text-align: center;
		}
	}
}