/* ==== ALERTS ==== */
.alert a {
	font-weight: bold;
}
.alert-nobold.alert a {
	font-weight: normal;
}
.alert-success a {
	color: @alert-success-text;
}
.alert-info a {
	color: @alert-info-text;
}
.alert-warning a {
	color: @alert-warning-text;
}
.alert-danger a {
	color: @alert-danger-text;
}