
&.exec-uploads-privacy_settings #profile-vids-privacy-contener,
&.section-uploads #profile-vids-release-rates-container,
#uploader-stats-filter {
	margin: 0 -@account-panel-padding @account-panel-padding;
	padding: 0 @account-panel-padding;
}

&.exec-uploads-privacy_settings #profile-vids-privacy,
&.section-uploads #profile-vids-release-rates,
#uploader-stats-filter {
	background: @text-invert-color;
	border: 1px solid @account-panel-border;
	margin: 0 0 @account-panel-padding;
	padding: 0;
}

&.section-uploads #video-tab-customize {
	.title-filter-container {
		max-width: 340px;
		float: left;
		margin-right: @account-panel-padding / 2;
		
		> input[type="text"] {
			border-top-left-radius: 2px;
			border-bottom-left-radius: 2px;
		}
	}
}


&.exec-uploads-privacy_settings #profile-vids-privacy,
&.section-uploads #profile-vids-release-rates {
	
	fieldset {
		margin: @account-panel-padding 0;
	}
	
	legend {
		border-bottom: 0;
		color: @text-color;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 6px;
	}
	
	select {
		margin-bottom: @account-panel-padding;
		
		& + .error-message {
			margin: -@account-panel-padding 0 @account-panel-padding;
		}
	}
	
	button {
		margin-bottom: @account-panel-padding/2;
		white-space: normal;
		width: 100%;
		
		& + .error-message {
			margin: -@account-panel-padding/2 0 @account-panel-padding/2;
		}
		
		&:last-of-type {
			margin-bottom: @account-panel-padding;
			
			& + .error-message {
				margin: -@account-panel-padding 0 @account-panel-padding;
			}
		}
		
		&:focus {
			outline: none;
		}
	}
	
	.error-message {
		color: @account-video-status-danger-color;
		text-align: center;
	}
}

&.section-uploads #profile-vids-release-rates {
	padding: @account-panel-padding @account-panel-padding @account-panel-padding/2;
	overflow: hidden;
	
	fieldset {
		display: inline-block;
		margin: 0 @account-panel-padding @account-panel-padding/2 0;
		width: auto;
		white-space: nowrap;
	}
	
	p {
		display: inline-block;
		line-height: 20px;
		padding: 7px 0;
		margin: 0 4px 0 0;
	}
	
	select {
		display: inline-block;
		height: 34px;
		line-height: 34px;
		margin: 0 4px 0 0;
		width: auto;
	}
	
	.info {
		cursor: pointer;
		float: left;
		font-size: 20px;
		text-align: center;
		width: 34px;
		
		.icon-f {
			display: inline-block;
			height: 20px;
			line-height: 20px;
		}
		
		.note {
			display: none;
		}
	}
}

#main-stats-chart {
	#stats-views-chart {
		position: relative;
		width: 100%;
		height: 500px;
	}
}

#uploader-stats-container {
	.uploader-stats-pies {
		> div {
			//display: none;
			&.px-0 {
				padding-left: 0;
				padding-right: 0;
			}
			
			> div:first-child {
				height: 300px;
			}
			
			p.stats-warning {
				margin-top: 5px;
				margin-bottom: 0;
				color: @theme-color;
				font-style: italic;
				text-align: center;
			}
			
			p.stats-title {
				font-weight: bold;
				font-size: 14px;
			}
			
			p.stats-value {
				a {
					color: @text-color;
				}
			}
			
			.open-top-ppv {
				cursor: pointer;
			}
		}
	}
}

#uploader-stats-container,
#uploader-chart-container {
	.x-overlay {
		height: auto;
		position: absolute;
		background: none;
		
		.x-body {
			background: rgba(0, 0, 0, .6);
			justify-content: center;
			display: flex;
			align-items: center;
		}
	}
}

#main #account-content-block #uploader-stats-filter.form-horizontal > fieldset#uploader-stats-filter_dates {
	padding: 0;
	
}

#uploader-stats-filter {
	padding: @account-panel-padding;
	
	.form-group {
		margin-bottom: 0;
	}
	
	.form-field-uploader-stats-filter_dates_from,
	.form-field-uploader-stats-filter_dates_to {
		width: calc(~"50% + 30px");
		float: left;
	}
	
	.form-field-uploader-stats-filter_dates_from {
		.input-group .input-group-addon:last-child {
			margin-right: 0;
			border-radius: 0;
			border-right: none;
		}
	}
	
	.form-field-uploader-stats-filter_dates_to {
		.input-group-addon:first-child {
			display: none;
		}
		
		.input-group-btn {
			button {
				margin: 0;
			}
			
			.dropdown-menu {
				margin: 2px 0 0;
				padding-left: 0;
				list-style: none;
				
				li {
					margin: 0;
					
					a {
						color: @text-color;
						text-decoration: none;
					}
				}
			}
		}
	}

	.form-field-uploader-stats-filter_accounts {
		margin: 5px -17px 0;
		
		.checkbox {
			display: inline-block;
			margin: 2px;
			padding: 0;
			border: 1px solid @account-tab-border;
			border-radius: 3px;
			font-size: 14px;
			line-height: 18px;
			
			label {
				padding: 7px 10px 7px 30px;
			}
			
			&:hover {
				background: @account-tab-border;
			}
		}
		
		button {
			margin: 2px;
			float: right;
		}
	}

	.form-field-uploader-stats-filter_accounts {
		.checkbox {
			&:first-child {
				label {
					font-weight: bold;
				}
			}
		}
	}

	.form-field-uploader-stats-filter_accounts.check-uncheck-available {
		.checkbox {
			&:first-child {
				border: 1px solid @state-warning-text;
			}

			&:nth-child(2n) {
				label {
					font-weight: normal;
				}
			}
		}
	}
}

&.exec-uploads-video_list {
	#account-content-block {
		& > .panel-body {
			background: @form-tag-adder-tag-legend-bg;
		}
	}
	
	.note {
		color: @gray;
		
		strong {
			color: @account-video-status-danger-color;
		}
	}
	
	.video-count {
		color: @text-color;
		font-size: 16px;
	}
}

.video-list {
	clear: both;
	
	.video {
		background: @text-invert-color;
		border: 1px solid @account-panel-border;
		margin-bottom: @account-panel-padding;
		padding: @account-panel-padding;
		
		& > .row {
			margin-left: -2*@account-panel-padding;
			margin-right: -2*@account-panel-padding;
		}
		
		.video-thumb {
			padding-left: 2*@account-panel-padding;
			padding-right: @account-panel-padding;
			text-align: center;
			
			img {
				border: 1px solid black;
				display: block;
				min-height: 20px;
				width: 100%;
				
				&.verified-pic {
					border-color: @theme-color;
				}
			}
			
			.video-details {
				padding-left: 0;
				padding-right: 0;
				
				.edit {
					display: block;
					
					a {
						font-size: 14px;
						font-weight: 400;
						margin: 4px 0 0;
						text-decoration: none;
					}
				}
			}
		}
		
		&.video-spotlight .video-thumb {
			img {
				border-color: @theme-color;
				position: relative;
			}
			
			.video-featured {
				color: #fff;
				background: @theme-color;
				position: absolute;
				left: 20px;
				top: 4px;
				padding: 2px 5px;
				
				.info {
					color: #fff;
				}
				
				.info-content {
					color: @text-color;
				}
			}
		}
		
		.video-details {
			color: @account-video-details-color;
			font-size: 14px;
			padding-left: @account-panel-padding;
			padding-right: 2*@account-panel-padding;
			
			& > p {
				position: relative;
				z-index: 2;
				
				&:hover {
					z-index: 3;
				}
			}
			
			& > .tag-adder {
				position: relative;
				z-index: 1;
			}
			
			.title {
				display: flex;
				align-items: center;
				color: @text-color;
				font-size: 16px;
				font-weight: bold;
				
				a {
					color: @text-color;
				}
			}

			.hive-problematic {
				color: @red-color;
			}
			
			.video-status {
				& > .icon-f:not(.info-back) {
					position: relative;
					top: 1px;
				}
			}
			
			.video-channel-home {
				color: @account-video-status-danger-color;
				
				.video-channel-home-long.info-content {
					left: auto;
					min-width: 0;
					right: -8px;
					white-space: nowrap;
				}
			}
			
			.btn-default {
				background: @text-invert-color;
				border: 1px solid @account-panel-border;
				color: @btn-default-color;
				white-space: normal;
				
				&:hover {
					background: @gray-lighter;
				}
			}
			
			.too-short {
				font-style: italic;
			}
			
			.video-nb-views {
				&.short {
					display: none;
				}
			}
			
			.video-actions {
				border-top: 1px solid @account-panel-border;
				margin-top: @account-panel-padding*2;
				overflow: hidden;
				padding-top: @account-panel-padding*2;
				
				.edit,
				.replace,
				.download,
				.del,
				.feature,
				.reset-feature {
					font-size: 16px;
					font-weight: normal;
					
					.icon-f {
						vertical-align: text-bottom;
					}
				}
				
				.edit,
				.replace,
				.download,
				.feature,
				.reset-feature {
					background: @text-invert-color;
					border: 1px solid @account-panel-border;
					float: left;
					margin-right: 4px;
					transition: all 0.2s;
					
					&:hover {
						background: @gray-lighter;
					}
					
					&.warning {
						border-color: @red-color;
						color: @red-color;
					}
					
					&.casting-selected {
						color: @gray-light2;
						
						&:hover {
							color: @gray-dark;
						}
					}
				}
				
				.del {
					background: none;
					float: right;
					
					&:hover {
						.txt {
							text-decoration: underline;
						}
						
						.icon-f {
							text-decoration: none;
						}
					}
				}
			}
			
			.thread-node {
				.thread-node-content {
					.thread-node-metadata {
						.icon-f {
							cursor: default;
						}
					}
				}
			}
		}
	}
}


#video-list-show-more {
	background: @account-panel-header-bg;
	border-radius: 4px;
	cursor: pointer;
	font-size: 20px;
	height: 36px;
	padding: 8px;
	position: absolute;
	right: 2px;
	text-align: center;
	top: 2px;
	transition: all 0.2s;
	width: 36px;
	
	&:hover {
		background: darken(@account-panel-header-bg, 5%);
	}
	
	.icon-f {
		line-height: 16px;
	}
	
	&.opened {
		.plus {
			opacity: 0;
		}
	}
	
	.plus,
	.badge {
		background: @theme-color;
		border-radius: 50%;
		color: white;
		display: block;
		font-size: 12px;
		font-weight: bold;
		height: 12px;
		line-height: 12px;
		position: absolute;
		right: 2px;
		top: 2px;
		width: 12px;
	}
	
	.badge {
		padding: 0;
		height: 14px;
		line-height: 14px;
		width: 14px;
	}
	
	.plus {
		opacity: 1;
		transition: all 0.2s;
	}
}

#video-list-more-infos {
	
	padding: 0 @account-panel-padding;
	margin: 0 -@account-panel-padding;
	
	#recently-deleted-videos,
	#recently-replacement-videos,
	.cur-stats {
		display: none;
		
		&.unread {
			display: block;

			#replacement-videos,
			#deleted-videos,
			#adrev-history-entries {
				.read {
					display: none;
				}
			}
		}
	}
	
	#recently-replacement-videos,
	#recently-deleted-videos {
		margin: @account-panel-padding*2 0;
	}
	
	#hide-unread-infos {
		cursor: pointer;
		display: none;
		margin-bottom: @account-panel-padding*2;
		text-align: center;
		
		.btn {
			font-size: 14px;
			margin-bottom: 0;
		}
		
		.icon-f {
			margin-right: 4px;
			position: relative;
			top: 1px;
		}
	}
	
	&.opened,
	&.unread {
		border-bottom: 2px solid @account-panel-border;
		margin-bottom: @account-panel-padding;
		
		#hide-unread-infos {
			display: block;
		}
	}
	
	&.opened {
		#recently-replacement-videos,
		#recently-deleted-videos,
		.cur-stats {
			&,
			&.unread {
				display: block;

				#replacement-videos,
				#deleted-videos,
				#adrev-history-entries {
					.read {
						display: block;
					}
				}
			}
		}
	}
	
	&.unread {
		#recently-deleted-videos,
		#recently-replacement-videos {
			&.unread {
				margin-bottom: @account-panel-padding*2;
			}
		}
	}
	
	.cur-stats {
		color: @gray;
		font-size: 12px;
		font-style: italic;
		margin-bottom: 10px;
		text-align: center;
	}
}


#recently-deleted-videos,
#recently-replacement-videos,
#pending-uploads {
	padding: 0 @account-panel-padding*2;
	
	p {
		&.block-title {
			font-size: 18px;
		}
		
		.title {
			font-weight: bold;
			font-size: 16px;
		}
	}

	#replacement-videos,
	#deleted-videos,
	#adrev-history-entries {
		background: @account-panel-bg;
		border: 1px solid @account-panel-border;
		position: relative;
		
		p {
			font-size: 14px;
			margin: 0 0 5px;
			padding: @account-panel-padding/2 @account-panel-padding*2;
			
			&:first-child {
				margin-top: 5px;
			}
		}
	}
}

#recently-deleted-videos {
	p {
		line-height: 20px;
		
		span {
			display: block;
		}
		
		.danger {
			color: @account-video-status-danger-color;
		}
	}

	#deleted-videos {
		//border-color: @account-video-status-danger-color;
		.open-view-appeal,
		p .danger {
			text-align: right;
		}
	}
}

#recently-replacement-videos {
	p {
		line-height: 20px;
	}
}

#pending-uploads {
	border-bottom: 2px solid @account-panel-border;
	margin: 0 -@account-panel-padding @account-panel-padding;
	padding: @account-panel-padding @account-panel-padding*3;
	
	.note {
		font-size: 13px;
	}
	
	ul {
		font-size: 14px;
		list-style: initial;
		margin-bottom: 15px;
	}
}

.x-popup.tooltip {
	.x-popup-content {
		background: @account-panel-bg;
		font-weight: bold;
		max-width: 320px;
		padding: @account-panel-padding/2 @account-panel-padding*2;
	}
	
	&.success {
		.x-popup-content {
			border: 1px solid @account-video-status-success-color;
			color: @account-video-status-success-color;
		}
		
		.x-popup-arrow {
			border-top-color: @account-video-status-success-color;
		}
	}
	
	&.warning {
		.x-popup-content {
			border: 1px solid @account-video-status-warning-color;
			color: @account-video-status-warning-color;
		}
		
		.x-popup-arrow {
			border-top-color: @account-video-status-warning-color;
		}
	}
	
	&.error {
		.x-popup-content {
			background: @theme-color;
		}
		
		.x-popup-arrow {
			border-top-color: @theme-color;
		}
	}
}
