@import "../../authorities/reporter_notice";

#account-content-block .fan-video {
	font-size: 16px;
	line-height: 18px;
	margin-left: 6px;
	color: @text-color;
	&:hover {
		text-decoration: none;
		color: @text-color;
	}
}

#video-tab-list .fan-video {
	font-size: 10px;
	line-height: 16px;
	margin-left: 0;
}

#signup-form {
	h2 {
		margin-left: 34px;
	}
	.form-field-signup-form_tos_pp .content {
		width: 100%;
		margin-left: 0;
	}
}