&.action-video_edit {
	#main {
		h6.flags-to-edit {
			margin: 10px 0;
		}
		.wrapper-infractions {
			.alert-warning,
			.alert-info {
				display: block;
				margin: 10px 0;
				
				a {
					text-decoration: none;
				}
			}
		}
		#major-issues,
		#minor-issues {
			display: none;
			background: @account-panel-border;
			border: 2px solid @account-video-details-color;
			border-radius: 5px;
			margin: 10px 0;
			padding: 10px;
			ul {
				font-size: 13px;
				margin: 0 0 0 20px;
				padding: 0;
			}
			.infraction .col-xs-12 {
				margin-bottom: 8px;
			}
		}
		#major-issues {
			border-color: #da7200;
		}
		.video-recap {
			font-size: 16px;
			margin-bottom: @account-tabs-padding*2;
			padding: 0 15px;
			
			h2 {
				background: @form-horizontal-legend-bg;
				border-bottom: 0;
				color: @text-color;
				margin: 10px 0 20px;
				padding: @account-tabs-padding*1.5 @account-tabs-padding*2;
				text-align: center;
			}
			
			.video-thumb {
				position: relative;
				img {
					width: 100%;
				}
				
				.btn {
					border-radius: 0;
					width: 100%;
					display: block;
					margin: 0;
					&.reset-feature,
					&.feature {
						float: right;
						width: 50%;
					}
				}

				.info {
					text-align: center;
				}
				
				.video-featured {
					font-size: @font-size-base;
					color: #fff;
					background: @theme-color;
					position: absolute;
					left: 20px;
					top: 4px;
					padding: 2px 5px;
					.info {
						color: #fff;
					}
					.info-content {
						color: @text-color;
					}
				}
			}
			
			.video-details {
				//padding-bottom: @account-tabs-padding*3;
				//padding-top: @account-tabs-padding*3;
				text-align: center;
				
				.video-file-name {
					color: @gray-light;
					font-size: 12px;
					margin-bottom: 2px;
				}
				
				.video-nb-views.short {
					display: none;
				}
				
				.text-danger {
					font-size: 14px;
				}
				
				p {
					float: left;
					width: 100%;
					margin-bottom: 0;
				}

				p.icon-fan-video-row {
					strong {
						display: flex;
						align-items: center;
						justify-content: center;
				
						.fan-video{
							margin-right: 6px;
							font-size: 14px;
							line-height: 16px;
						}
					}
				}
				
				P.ticket-red-row {
					margin-top: 16px;
				}
				
				a {
					color: @text-color;
				}
				
				.label,
				.detail {
					display: block;
					float: left;
					line-height: 24px;
					width: 50%;
					padding: 0em 0.6em;
					.video-status {
						display: flex;
    					align-items: center;
					}
					.hive-problematic {
						color: @red-color;
					}
				}
				
				.label {
					color: @gray;
					padding-right: 4px;
					padding-top: 1px;
					text-align: right;
				}
				
				.detail {
					color: @text-color;
					font-weight: bold;
					padding-left: 6px;
					text-align: left;
					
					&.ads-revshare {
						font-size: 13px;
						
						.btn {
							vertical-align: middle;
							white-space: normal;
						}
					}
				}
				
				.too-short {
					font-style: italic;
				}
			}
			
			.video-channel-home {
				color: @account-video-status-danger-color;
				
				.video-channel-home-long.info-content {
					left: auto;
					min-width: 0;
					right: -8px;
					white-space: nowrap;
				}
			}
		}
		
		
		#content {
			#video-edit-tabs {
				ul {
					margin: 0 15px;
					a {
						color: @text-color;
					}
				}
				.tabs {
					border: 1px solid #d9d9d9;
					border-top: none;
					margin: 0 15px;
					padding-top: 15px;
				}
			}
			
			#revshare_form_contener {
				.form-horizontal {
					margin-bottom: 30px;
					
					#enable_revshare_terms {
						.form-group {
							float: left;
							
							.help-block {
								line-height: 20px;
								margin-bottom: 0;
								margin-top: -16px;
							}
							
							.checkbox.btn {
								border: 0;
								
								&,
								label {
									background: transparent;
								}
							}
						}
					}
				}
			}
		}
		
		.certify-list {
			float: left;
			margin-bottom: 12px;
			
			li, p {
				font-size: 16px;
			}
			
			ul {
				list-style: initial;
			}
		}
		
		.instructions-revshare {
			ul {
				list-style: initial;
			}
			
			li, p {
				color: @gray-darker;
				font-size: 13px;
			}
			
			padding: 0 30px;
		}
		
		.form-horizontal {
			& > fieldset {
				legend {
					padding-left: @account-tabs-padding*2;
				}
			}
		}
		
		#comments-listing {
			margin: 0 15px 15px;
		}
		
	}
	
	#video-stats-list {
		td.pending-stats {
			text-align: center;
		}
	}
	
}

&.exec-uploads-video_notice_discussion {
	.form-horizontal .form-group {
		margin-bottom: 0;
	}
	.x-thread {
		margin-top: 15px;
		.thread-node {
			.thread-node-content {
				padding-left: 0;
			}
		}
	}
}

&.exec-uploads-move_videos_request #account-content-block {
	dd {
		ul {
			margin: 0;
			padding-left: 2em;
		}
	}
	ul.form-control-static {
		margin-top: 0;
		ul {
			margin: 0;
			padding-left: 1em;
		}
	}
}
