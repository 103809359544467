
.logo-red {
	color: @red-color;
}

.head__top__container {
	h1 {
		text-align: left;
		margin: 0;
		a.head__logo {
			color: @theme-color;
			float: none;
			display: inline;
			background: none;
			text-decoration: none;
			box-shadow: none;
		}
	}
}
#header-mobile-right {
	margin-left: auto;
}

body#red-page {
	.btn-primary {
		.btn-custom(#fff, @theme-color, darken(@theme-color, 10%), darken(@theme-color, 20%), lighten(@theme-color, 5%), darken(@theme-color, 5%), alpha(@theme-color, 0.5));
	}
	.btn-danger {
		.button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
	}
}

@media (max-width: @screen-all-mobile) {
	#header {
		.animated-hamburger.init-ok span {
			background: @theme-color;
		}
		.header-icons #site-settings {
			color: @theme-color;
			.icf-cog {
				color: #fff;
			}
		}
		h1 {
			line-height: 38px;
			margin: 3px 0;
			font-size: 28px;
			text-align: center;
			a.logo {
				padding: 0;
			}
		}
	}
	#page {
		padding-top: 0;
	}
}
