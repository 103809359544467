
.nav-tabs {
	background: @account-tabs-bg;
	border-color: @gray-bg;
	padding: @account-tabs-padding @account-tabs-padding 0;
	margin-bottom: @account-panel-padding*2;
	& > li {
		& > a {
			border-radius: 3px 3px 0 0;
			font-size: 16px;
			padding: 5px @account-tabs-padding*2;
			text-decoration: none;
			&:hover{
				border-bottom-color: @gray-bg;
			}
			&.active {
				&,
				&:focus,
				&:hover {
					background: @body-bg;
					border-color: @gray-bg;
					border-bottom-color: transparent;
					color: @text-color;
				}
			}
		}
	}
}
.panel {
	background: @account-panel-bg;
	border-color: @account-panel-border;
	.panel-heading {
		background: @account-panel-header-bg;
		border-color: @account-panel-border;
		color: @text-color;
		font-size: 16px;
		line-height: @dashboard-panel-link-line-height;
		padding: @dashboard-panel-link-padding-v 40px @dashboard-panel-link-padding-v @account-panel-padding*2;
		position: relative;
	}
	.panel-heading,
	.panel-body {
		.badge{
			border-radius: 3px;
			font-weight: bold;
			padding: 3px 4px;
			line-height: 10px;
			vertical-align: text-bottom;
			&.alert {
				background: @theme-color;
				color: white;
			}
		}
	}
	.panel-body {
		border-color: @account-panel-border;
		color: @text-color;
		transition: all 0.5s ease-in-out;
		.list-group{
			margin-bottom: @account-panel-padding;
		}
	}
}
.list-group {
	.list-group-item {
		//display: flex;
		font-size: 16px;
		line-height: @dashboard-panel-link-line-height;
		padding: @dashboard-panel-link-padding-v @account-panel-padding*2;
		text-decoration: none;
		&,
		&.active,
		&:hover {
			border-color: @account-panel-border;
			color: @text-color;
		}
		&.active,
		&:active,
		&:focus{
			background-color: @dashboard-panel-link-active-bg;
		}
		&:hover{
			background-color: @dashboard-panel-link-hover-bg;
		}
		&.with-help{
			.list-line{
				padding-right: 42px;
				[dir='rtl'] & {
					padding-left: 42px;
					padding-right: @account-panel-padding*2;
				}
			}
		}
		.list-line{
			display: flex;
			padding: @dashboard-panel-link-padding-v @account-panel-padding*2;
			.list-enum {
				flex: none;
			}
			.list-label,
			.status{
				flex: auto;
			}
			.list-label{
				padding-right: @account-panel-padding*2;
			}
		}
		a.list-line{
			color: @text-color;
			text-decoration: none;
			&:hover{
				background-color: darken( @dashboard-panel-link-bg, 5% );
			}
			.status{
				text-decoration: underline;
			}
		}
		&.list-group-item-danger a.list-line{
			color: @red-color;
			&:hover {
				background: darken(@dashboard-panel-link-danger-bg, 10%);
			}
		}
		&.list-group-item-warning a.list-line{
			color: darkorange;
			&:hover {
				background: darken(@dashboard-panel-link-warning-bg, 10%);
			}
		}
		.list-enum {
			span {
				display: inline-block;
				border: 1px solid @text-color;
				border-radius: 3px;
				color: @text-color;
				float: left;
				font-size: 11px;
				line-height: 18px;
				margin: -1px 6px 0 0;
				padding: 0 5px;
				vertical-align: text-bottom;
			}
		}
		&.list-group-item-danger {
			background: @dashboard-panel-link-danger-bg;
			.list-enum span {
				background: @theme-color;
				border-color: @theme-color;
				color: @text-invert-color;
				font-weight: bold;
			}
		}
		&.list-group-item-warning {
			background: @dashboard-panel-link-warning-bg;
			.list-enum span {
				background: darkorange;
				border-color: darkorange;
				color: @text-invert-color;
				font-weight: bold;
			}
		}
		.status{
			text-align: right;
			[dir='rtl'] & {
				text-align: left;
			}
		}
		.help-toggle{
			background: rgba(0,0,0,0);
			border-radius: 4px;
			cursor: pointer;
			display: block;
			height: 36px;
			line-height: 38px;
			position: absolute;
			top: 2px;
			right: 2px;
			text-align: center;
			text-decoration: none;
			transition: all 0.2s;
			width: 36px;
			[dir='rtl'] & {
				left: 2px;
				right: auto;
			}
			&:hover{
				background: rgba(0,0,0,0.07);
			}
		}
		.help-d{
			background: @dashboard-panel-bg;
			border: 0px solid @dashboard-panel-link-bg;
			display: block;
			font-size: 15px;
			margin: 0 @account-panel-padding;
			max-height: 0;
			opacity: 0;
			overflow: hidden;
			position: relative;
			padding: 0 @account-panel-padding*2 0 38px;
			transition: all 0.5s ease-in-out;
			.help-d-content{
				position: relative;
				z-index: 2;
				.help_title{
					margin: 15px 0 5px;
					font-weight: bold;
					&:first-child{
						margin-top: 0;
					}
				}
				.help_list{
					list-style: initial;
					padding-left: 20px;
				}
			}
			.help-d-bg{
				color: darken(@dashboard-panel-bg, 5%);
				font-size: 80px;
				left: -24px;
				position: absolute;
				top: -15px;
				z-index: 1;
			}
		}
		&.help-opened{
			.help-d{
				border: 1px solid @account-panel-border;
				max-height: 1000px;
				opacity: 1;
				margin-top: @account-panel-padding/2;
				margin-bottom: @account-panel-padding;
				padding-bottom: @account-panel-padding;
				padding-top: @account-panel-padding;
			}
		}
	}
}

.bootstrap-datetimepicker-widget {
	background: @body-bg;
}
